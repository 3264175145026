import React, {Component} from 'react';
import {
  ProForm,
  ProFormText,
  ProFormDateTimePicker,
  ProFormSelect,
  ProFormTextArea,
  ProFormList, ProFormGroup,
} from '@ant-design/pro-components';
import {Modal, Divider} from "antd";
import {LanguageData, JsonKey, TableDataSource} from "./Model";
import '../../css/style_modal_scroll.css'
import PostServer from "../../Utils/http";
import {MethodAddLocalSysMail} from "../../Utils/method";
import {MessageError} from "../../Template/MessageNet";


export default class AddMailForm extends Component<any> {
  state = {
    visible: false,
    json: JsonKey,
    call: null,
  };

  constructor(props: any) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  handleCancel = (e: any) => {
    this.onClose()
  };

  onClose() {
    this.setState({
      visible: false,
      json: JsonKey,
    });
  }

  //判断传过来的visible 改变模态框状态是否隐藏
  componentWillReceiveProps(nextProps: any) {
    if (nextProps.visible !== this.state.visible) {
      const localJson: any = JSON.parse(JSON.stringify(JsonKey));
      const reqJson = Object.keys(nextProps.json);
      const locJson = Object.keys(localJson);
      reqJson.forEach((j1) => {
        const jRep = j1.replace('-', '_');
        locJson.forEach((j2) => {
          if (j2.toString() === jRep) {
            localJson[j2] = nextProps.json[j1];
          }
        });
      })
      this.setState({
        visible: nextProps.visible,
        json: localJson,
        call: nextProps.call,
      })
    }
  }

  // onSubmit 添加,创建一条数据
  async onSubmit(value: any) {
    if (value.reward) {
      for (let item of value.reward) {
        item.id = Number(item.id);
        item.type = Number(item.type);
        item.num = Number(item.num);
      }
    } else {
      value.reward = [];
    }
    value.reward = JSON.stringify(value.reward);
    value.id = Number(value.id);
    const data = await PostServer(MethodAddLocalSysMail, JSON.stringify({
      sys_mail: value,
    }));
    this.setState({isOpen: false})
    if (data === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (data.err_code !== 0) {
      MessageError('Error', `Server to Code:${data.err_code}`);
      return;
    }
    value.key = Number(data.key);
    value.status = 'error';
    TableDataSource.push(value);
    const callback: any = this.state.call;
    callback(value);
    this.onClose();
  }

  onFinishFailed(event: any) {
    if (event.errorFields) {
      for (let it of event.errorFields) {
        const doc = document.getElementById(it.name[0]) as HTMLInputElement;
        doc.focus();
        break;
      }
    }
  }

  FormRules: any = []

  render() {
    return (
      <>
        <Modal
          key={'addmail'}
          title={'Add Mail'} //标题
          visible={this.state.visible} //visible 判断是否显示模态框 (true | false)
          onCancel={this.handleCancel}
          footer={false}
          width={800}

          destroyOnClose={true}
          maskClosable={false}
          className={"overflow-y：auto"}
        >
          <ProForm
            title="New Mail"
            onFinish={async (values) => {
              await this.onSubmit(values);
              return true;
            }}
            onReset={() => {
              this.setState({
                json: JSON.parse(JSON.stringify(JsonKey)),
              });
            }}
            initialValues={this.state.json}
            request={async (params) => {
              return {
                id: this.state.json.id,
                max_version: this.state.json.max_version,
                min_version: this.state.json.min_version,
                start_time: this.state.json.start_time,
                stop_time: this.state.json.stop_time,
                platform: this.state.json.platform,
                reward: JSON.parse(this.state.json.reward),
                max_created_at: this.state.json.max_created_at,
                des: this.state.json.des,
                name_cn: this.state.json.name_cn,
                name_de: this.state.json.name_de,
                name_en: this.state.json.name_en,
                name_fr: this.state.json.name_fr,
                name_id: this.state.json.name_id,
                name_ja: this.state.json.name_ja,
                name_ko: this.state.json.name_ko,
                name_pt: this.state.json.name_pt,
                name_th: this.state.json.name_th,
                name_vi: this.state.json.name_vi,
                name_zh: this.state.json.name_zh,
                content_cn: this.state.json.content_cn,
                content_de: this.state.json.content_de,
                content_en: this.state.json.content_en,
                content_fr: this.state.json.content_fr,
                content_id: this.state.json.content_id,
                content_ja: this.state.json.content_ja,
                content_ko: this.state.json.content_ko,
                content_pt: this.state.json.content_pt,
                content_th: this.state.json.content_th,
                content_vi: this.state.json.content_vi,
                content_zh: this.state.json.content_zh,
              };
            }}
            onFinishFailed={this.onFinishFailed}
          >
            <ProFormText width="md" name="id" label="邮件ID" tooltip="必填" placeholder="请输入邮件ID"/>
            <ProForm.Group>
              <ProFormDateTimePicker width="md" name="start_time" label="开始时间" rules={this.FormRules}/>
              <ProFormDateTimePicker width="md" name="stop_time" label="结束时间" rules={this.FormRules}/>
            </ProForm.Group>
            <ProForm.Group>
              <ProFormText width="md" name="max_version" label="最大版本号" placeholder="请输入最大版本号" rules={this.FormRules}/>
              <ProFormText width="md" name="min_version" label="最小版本号" placeholder="请输入最小版本号" rules={this.FormRules}/>
            </ProForm.Group>
            <ProFormSelect
              options={[
                {
                  value: 'Android',
                  label: 'Android',
                },
                {
                  value: 'iOS',
                  label: 'iOS',
                },
              ]}
              width="md"
              name="platform"
              label="平台限制"
            />
            <ProFormList name={'reward'} label={'Reward(奖励)'} creatorButtonProps={{
              creatorButtonText: 'Add Reward Item',
            }}>
              <ProFormGroup>
                <ProFormText name={'type'} label={'Type'} rules={this.FormRules}></ProFormText>
                <ProFormText name={'id'} label={'ID'} rules={this.FormRules}></ProFormText>
                <ProFormText name={'num'} label={'Num'} rules={this.FormRules}></ProFormText>
              </ProFormGroup>
            </ProFormList>
            <ProFormDateTimePicker width="md" name="max_created_at" label="创号时间"/>
            <ProFormTextArea colProps={{span: 24}} name="des" label="备注"/>
            <Divider plain>多语言配置</Divider>
            {LanguageData.map((model, index) => (
              <div key={index}>
                <ProFormText width="md" name={model.key_title} label={model.title}
                             placeholder={`输入${model.title}`} rules={this.FormRules}/>
                <ProFormTextArea name={model.key_content} colProps={{span: 24}}
                                 label={model.content}
                                 placeholder={`输入${model.content}`} rules={this.FormRules}/>
              </div>
            ))}
          </ProForm>
        </Modal>
      </>
    );
  }
}