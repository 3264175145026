import React, {Component} from 'react';
import DefaultTemplate from "../../Template/DefaultTemplate";
import {Button, Select, Space, Table, Typography} from "antd";
import '../../css/style_app.css'
import {MethodMarkWorldCup, MethodWorldCupInfo} from "../../Utils/method";
import PostServer from "../../Utils/http";
import {MessageError, MessageSuccess} from "../../Template/MessageNet";
import {WorldCupColumns} from "./CupModel";

export default class WorldCupConfig extends Component<any> {
  state = {
    isOpen: false,
    ToDayId: 1,
    WinCupId: 1,
    MainCupId: 1,
    GuestCupId: 1,
    WorldCupSource: [],
  };

  constructor(props: any) {
    super(props);
    this.onClickWinCup = this.onClickWinCup.bind(this);
    this.onChangeWinCup = this.onChangeWinCup.bind(this);
    this.onChangeMain = this.onChangeMain.bind(this);
    this.onChangeGuest = this.onChangeGuest.bind(this);
    this.onChangeToDay = this.onChangeToDay.bind(this);
    this.onClickVS = this.onClickVS.bind(this);
    this.onLoadTable = this.onLoadTable.bind(this);
  }


  async onClickWinCup() {
    const data = {worldCupId: this.state.ToDayId, cupId: [this.state.WinCupId], upType: 2}
    this.setState({isOpen: true});
    const req = await PostServer(MethodMarkWorldCup, JSON.stringify(data));
    this.setState({isOpen: false});
    if (req === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (req.err_code === 25) {
      MessageError('Error', "今日没有比赛!");
      return;
    }
    if (req.err_code !== 0) {
      MessageError('Error', `Server to Code:${req.err_code}`);
      return;
    }
    MessageSuccess('Success', '');
    await this.onLoadTable();
  }

  async onClickVS() {
    if (this.state.MainCupId === this.state.GuestCupId){
      MessageError('Error', '相同的队伍,怎么比赛??');
      return;
    }
    const data = {worldCupId: this.state.ToDayId, cupId: [this.state.MainCupId, this.state.GuestCupId], upType: 1};
    this.setState({isOpen: true});
    const req = await PostServer(MethodMarkWorldCup, JSON.stringify(data));
    this.setState({isOpen: false});
    if (req === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (req.err_code === 25) {
      MessageError('Error', "今日没有比赛!");
      return;
    }
    if (req.err_code !== 0) {
      MessageError('Error', `Server to Code:${req.err_code}`);
      return;
    }
    MessageSuccess('Success', '');
    await this.onLoadTable();
  }

  onChangeWinCup(value: number) {
    this.setState({WinCupId: value})
  }

  onChangeMain(value: number) {
    this.setState({MainCupId: value})
  }

  onChangeGuest(value: number) {
    this.setState({GuestCupId: value})
  }

  onChangeToDay(value: number) {
    this.setState({ToDayId: value});
  }

  async componentDidMount() {
    await this.onLoadTable();
  }

  async onLoadTable(){
    this.setState({isOpen: true});
    const req = await PostServer(MethodWorldCupInfo, JSON.stringify(''));
    this.setState({isOpen: false});
    if (req === undefined) {
      console.log('Error', '请求失败!');
      return;
    }
    if (req.err_code !== 0) {
      console.log('Error', `Server to Code:${req.err_code}`);
      return;
    }
    console.log(req.info)
    req.info.sort((a: any, b: any) => {
      return b.id - a.id;
    })
    this.setState({WorldCupSource: req.info});
  }

  ToDay = [
    {
      label: '1 场',
      value: 1,
    },
    {
      label: '2 场',
      value: 2,
    },
    {
      label: '3 场',
      value: 3,
    },
    {
      label: '4 场',
      value: 4,
    },
    {
      label: '5 场',
      value: 5,
    },
    {
      label: '6 场',
      value: 6,
    },
    {
      label: '7 场',
      value: 7,
    },
    {
      label: '8 场',
      value: 8,
    },
    {
      label: '11 场',
      value: 11,
    },
    {
      label: '12 场',
      value: 12,
    },
    {
      label: '13 场',
      value: 13,
    },
    {
      label: '14 场',
      value: 14,
    },
    {
      label: '18 场',
      value: 18,
    },
    {
      label: '19 场',
      value: 19,
    },
    {
      label: '22 场',
      value: 22,
    },
    {
      label: '23 场',
      value: 23,
    }];

  CupId = [
    {
      label: '荷兰',
      value: 1,
    },
    {
      label: '厄瓜多尔',
      value: 2,
    },
    {
      label: '塞内加尔',
      value: 3,
    },
    {
      label: '卡塔尔',
      value: 4,
    },
    {
      label: '英格兰',
      value: 5,
    },
    {
      label: '威尔士',
      value: 6,
    },
    {
      label: '美国',
      value: 7,
    },
    {
      label: '伊朗',
      value: 8,
    },
    {
      label: '沙特阿拉伯',
      value: 9,
    },
    {
      label: '波兰',
      value: 10,
    },
    {
      label: '墨西哥',
      value: 11,
    },
    {
      label: '阿根廷',
      value: 12,
    },
    {
      label: '法国',
      value: 13,
    },
    {
      label: '突尼斯',
      value: 14,
    },
    {
      label: '丹麦',
      value: 15,
    },
    {
      label: '澳大利亚',
      value: 16,
    },
    {
      label: '哥斯达黎加',
      value: 17,
    },
    {
      label: '德国',
      value: 18,
    },
    {
      label: '日本',
      value: 19,
    },
    {
      label: '西班牙',
      value: 20,
    },
    {
      label: '比利时',
      value: 21,
    },
    {
      label: '加拿大',
      value: 22,
    },
    {
      label: '克罗地亚',
      value: 23,
    },
    {
      label: '摩洛哥',
      value: 24,
    },
    {
      label: '巴西',
      value: 25,
    }, {
      label: '喀麦隆',
      value: 26,
    },
    {
      label: '塞尔维亚',
      value: 27,
    },
    {
      label: '瑞士',
      value: 28,
    },
    {
      label: '加纳',
      value: 29,
    },
    {
      label: '韩国',
      value: 30,
    },
    {
      label: '葡萄牙',
      value: 31,
    },
    {
      label: '乌拉圭',
      value: 32,
    }];

  indexPage: string[] = ['sub120', '1201'];

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <Table
          rowKey={'id'}
          pagination={{pageSize: 8}}
          columns={WorldCupColumns}
          dataSource={this.state.WorldCupSource}
        />

        <div className={'div-center-temp'}>
          <Space direction={'vertical'}>
            <Space>
              <Typography.Text style={{fontSize: 16}}>Club Guess ID:</Typography.Text>
              <Select
                defaultValue={this.state.ToDayId}
                style={{width: 120}}
                options={this.ToDay}
                onChange={this.onChangeToDay}
              ></Select>
            </Space>
            <Space>
              <Typography.Text style={{fontSize: 16}}>Win Team:</Typography.Text>
              <Select
                defaultValue={1}
                style={{width: 120}}
                options={this.CupId}
                onChange={this.onChangeWinCup}
              ></Select>
              <Button type="primary" ghost onClick={this.onClickWinCup}>
                Mark Win Cup
              </Button>
            </Space>
            <Space>
              <Typography.Text style={{fontSize: 16}}>主队:</Typography.Text>
              <Select
                defaultValue={1}
                style={{width: 120}}
                options={this.CupId}
                onChange={this.onChangeMain}
              ></Select>
              <Typography.Text style={{fontSize: 16}}>客队:</Typography.Text>
              <Select
                defaultValue={1}
                style={{width: 120}}
                options={this.CupId}
                onChange={this.onChangeGuest}
              ></Select>
              <Button type="primary" ghost onClick={this.onClickVS}>
                Mark Cup VS
              </Button>
            </Space>
          </Space>

        </div>

      </DefaultTemplate>
    );
  }
}