export const MethodLogin = 'login'
export const MethodAddUser = 'adduser'
export const MethodSysMail = 'sysmail'
export const MethodUserMail = 'usermail'
export const MethodUserCmd = 'usercmd'
export const MethodParseCouldUserMail = 'parsecouldusermail'
export const MethodParseCouldSysMail = 'parsecouldsysmail'
export const MethodAddLocalUserMail = 'addlocalusermail'
export const MethodAddLocalSysMail = 'addlocalsysmail'
export const MethodAddLocalUserCmd = 'addlocalusercmd'
export const MethodCopyUserSave = 'copyusersave'
export const MethodParseCouldUserCmd = 'parsecouldusercmd'
export const MethodDelRow = 'delrow'
export const MethodTransactionHistory = 'transactionhistory'
export const MethodMarkPassword = 'markpassword'
export const MethodGetCrossUserSave = 'getcopyusersaveapps'
export const MethodFindUserSaveApp = 'findcopyusersaveapp'
export const MethodCopyUserSaveApp = 'copyusersaveapps'
export const MethodFindUserInfo = 'finduserinfo'
export const MethodTransUserSave = 'transusersave'
export const MethodHalloweenRank = 'gethalloweenrank'
export const MethodHalloweenRankDel = 'delhalloweenrank'
export const MethodHalloweenRankPlayerInfo = 'find_player_rank_id'
export const MethodHalloweenExport = 'exporthalloween'
export const MethodHalloweenRankUser = 'halloweenuserrank'
export const MethodHalloweenRankUserDel = 'halloweenuserrankdel'
export const MethodRefundHistory = 'refundhistory'
export const MethodHalloweenExportUser = 'exporthalloweenuser'
export const MethodGetVersion = 'getversion'
export const MethodMarkVersion = 'markversion'
export const MethodBannedUser = 'banneduser'
export const MethodMarkAccountMenus = 'markaccountmenus'
export const MethodMarkSysMail = 'marksysmail'
export const MethodMarkWhiteList = 'markwhitelist'
export const MethodFindUserSave = 'findusersave'
export const MethodDeleteRows = 'deleterows'
export const MethodMarkUserSaveId = 'markusersaveid'
export const MethodUserMail2 = 'usermail2'
export const MethodAddLocalUserMail2 = 'addlocalusermail2'
export const MethodAddCouldUserMail2 = 'addcouldusermail2'
export const MethodDelUserMail2 = 'delusermail2'
export const MethodDelAllUserMail2 = 'delallusermail2'
export const MethodCouldCopy = 'couldusercopy'
export const MethodCouldCopyApps = 'couldcrossusercopy'
export const MethodMarkWorldCup = 'markworldcup'
export const MethodWorldCupInfo = 'worldcupinfo'
export const MethodRankList = 'ranklist'
export const MethodRankDel = 'rankdel'
export const MethodRankExport = 'rankexport'
export const MethodSpringFestivalRank = 'springfestivalrank'
export const MethodSpringFestivalDel = 'springfestivaldel'
export const MethodFindNetUserInfo = 'findnetuserinfo'
export const MethodSpringFestivalExport = 'springfestivalexport'
export const MethodValentineRank = 'valentinerank'
export const MethodValentineRankExport = 'valentinerankexport'
export const MethodValentineRankDel = 'valentinerankdel'
export const MethodVersionMail = 'versionmail'
export const MethodVersionMailAdd = 'versionmailadd'
export const MethodVersionMailPushCloud = 'versionmailpushcloud';
