import React, {Component, ReactNode} from "react";
import {EditableProTable, ProColumns} from "@ant-design/pro-components";
import {DatePicker, Divider, Typography, InputNumber, Select, Button, Popconfirm} from 'antd';
import DefaultTemplate from "../../Template/DefaultTemplate";
import {HalloweenRankType, HalloweenRankSource} from "./HalloweenRankModel";
import PostServer, {PostXlsx} from "../../Utils/http";
import {
  MethodHalloweenExport,
  MethodHalloweenRank,
  MethodHalloweenRankDel,
} from "../../Utils/method";
import moment from "moment";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import {Option} from "antd/es/mentions";
import RankPlayerInfo from "./RankPlayerInfo";
import {MessageError} from "../../Template/MessageNet";


export default class HalloweenRank extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      visible: false,
      UserId: '',
      Call: this.onCall,
      RankType: 9,
      RankNum: 100,
      RankDate: moment(new Date()).format('YYYY-MM-DD'),
    };
    this.onDel = this.onDel.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.GetSource = this.GetSource.bind(this);
    this.onMoreInfo = this.onMoreInfo.bind(this);
    this.onCall = this.onCall.bind(this);
    this.onExport = this.onExport.bind(this);
  }

  HalloweenRankColumns: ProColumns<HalloweenRankType>[] = [
    {
      title: 'User ID',
      dataIndex: 'user_id',
      readonly: true,
      width: '120px'
    }, {
      title: 'User Name',
      dataIndex: 'user_name',
      readonly: true,
      width: '120px'
    }, {
      title: 'User Rank',
      dataIndex: 'user_rank',
      readonly: true,
      width: '50px'
    }, {
      title: 'User Score',
      dataIndex: 'user_score',
      width: '80px'
    }, {
      title: 'User Level',
      dataIndex: 'user_level',
      width: '80px'
    }, {
      title: 'Operation',
      valueType: 'option',
      width: 120,
      render: (text, record, idx, action) => [
        <Popconfirm
          title="删除,将删除所有榜的信息,并加入黑名单?"
          onConfirm={() => this.onDel(text, record, idx, action)}
          okText="Yes"
          cancelText="No"
        >
          <a href="#">DELETE</a>
        </Popconfirm>,
        <Button type="primary" ghost onClick={() => this.onMoreInfo(text, record, action)}>
          MORE INFO...
        </Button>
      ]
    }
  ];

  async onMoreInfo(text: ReactNode, record: HalloweenRankType, action: any) {
    this.setState({visible: true, UserId: record.user_id, Call: this.onCall});
  }

  onCall() {
    this.setState({visible: false, UserId: ''});
  }

  async onDel(text: ReactNode, record: HalloweenRankType, index: number, _: any) {
    this.setState({isOpen: true});
    const req = {user_id: record.user_id, date_time: this.state.RankDate, idx: record.id};
    const rsp = await PostServer(MethodHalloweenRankDel, JSON.stringify(req));
    this.setState({isOpen: false});
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    HalloweenRankSource.splice(index, 1);
    this.formRef?.current.reload();
  }

  async onSelect() {
    this.setState({isOpen: true});
    const req = {rank_type: this.state.RankType, date_time: this.state.RankDate, num: this.state.RankNum};
    const rsp = await PostServer(MethodHalloweenRank, JSON.stringify(req))
    this.setState({isOpen: false})
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    HalloweenRankSource.splice(0, HalloweenRankSource.length);
    if (rsp.ranks) {
      HalloweenRankSource.push(...rsp.ranks);
    }
    this.formRef?.current.reload();
  }

  async onExport() {
    this.setState({isOpen: true});
    const req = {rank_type: this.state.RankType, date_time: this.state.RankDate, num: this.state.RankNum};
    const fileName = `Halloween_${req.date_time}.xlsx`;
    await PostXlsx(MethodHalloweenExport, JSON.stringify(req), fileName);
    this.setState({isOpen: false});
  }

  GetSource() {
    let tableSource: HalloweenRankType[] = JSON.parse(JSON.stringify(HalloweenRankSource));
    return tableSource;
  }

  componentWillUnmount() {
    HalloweenRankSource.splice(0, HalloweenRankSource.length);
  }

  formRef: any = {};
  indexPage: string[] = ['sub110', '1101'];
  RankList = [
    {Id: 9, Desc: 'History Halloween Distance World'},
    {Id: 10, Desc: 'History Halloween Kill World'},
    {Id: 7, Desc: 'New Halloween Distance World'},
    {Id: 8, Desc: 'New Halloween Kill World'},
  ];

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <EditableProTable<HalloweenRankType>
          rowKey={'id'}
          scroll={{x: 960}}
          columns={this.HalloweenRankColumns}
          recordCreatorProps={false}
          actionRef={this.formRef}
          toolBarRender={() => [
            <Typography.Text style={{fontSize: 15}}>Rank Type:</Typography.Text>,
            <Select defaultValue={this.RankList[0].Desc} style={{width: 300}} onChange={(v) => {
              this.setState({RankType: Number(v)})
            }}>
              {this.RankList.map((item, idx) => {
                return (<Option value={`${item.Id}`}>{item.Desc}</Option>)
              })}
            </Select>,
            <Divider type="vertical"/>,
            <InputNumber addonBefore="Count" style={{width: 150}} defaultValue={this.state.RankNum}
                         value={this.state.RankNum}
                         onChange={(v) => {
                           this.setState({RankNum: v});
                         }}/>,
            <Divider type="vertical"/>,
            <Typography.Text style={{fontSize: 15}}>Select DATE:</Typography.Text>,

            <DatePicker defaultValue={moment(new Date().getTime())}
                        format={'YYYY-MM-DD'} locale={locale} size={'large'}
                        onChange={(_, dateString) => {
                          this.setState({RankDate: dateString});
                        }}
            />,
            <Button type="primary" ghost onClick={this.onSelect}>
              SELECT
            </Button>,
            <Button type="primary" ghost onClick={this.onExport}>
              EXPORT
            </Button>
          ]}
          pagination={{
            pageSize: 20,
          }}
          request={async () => {
            const dbSource = this.GetSource();
            return {
              data: dbSource,
              total: dbSource.length,
              success: true,
            }
          }}
        >
        </EditableProTable>
        <RankPlayerInfo visible={this.state.visible} dateTime={this.state.RankDate} userId={this.state.UserId}
                        onCall={this.state.Call}/>
      </DefaultTemplate>
    );
  }
}