import React from "react";

export type HalloweenRankType = {
  id: React.Key,
  user_id: string,
  user_name: string,
  user_rank: number,
  user_score: number,
  user_level: number,
}

export const HalloweenRankSource: HalloweenRankType[] = [];

export const HalloweenRankUserSource: HalloweenRankType[] = [];
