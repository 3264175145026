import React from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import MailTable from "../View/Mail/MailTable";
import UserMailTable from "../View/UserMail/UserMailTable";
import Default from "./Default";
import UserCmdMailTable from "../View/UserCmdMail/UserCmdMailTable";
import Login from "../View/Login/Login";
import AddAdmin from "../View/Setting/AddAdmin";
import NotFound from "../View/Util/NotFound";
import MarkSeverTime from "../View/Setting/MarkSeverTime";
import FindHistoryOrders from "../View/AppStore/FindHistoryOrders";
import MarkPassword from "../View/Setting/MarkPassword";
import UserInfo from "../View/User/UserInfo";
import MarkVersion from "../View/Setting/MarkVersion";
import HalloweenRank from "../View/Halloween/HalloweenRank";
import HalloweenUserRank from "../View/Halloween/HalloweenUserRank";
import RankUserDel from "../View/Halloween/RankUserDel";
import FindRefundHistory from "../View/AppStore/FindRefundHistory";
import MarkAccountMenu from "../View/Setting/MarkAccountMenu";
import NewUserMailTable from "../View/UserMail/NewUserMailTable";
import CrossUserCopy2 from "../View/User/CrossUserCopy2";
import UserCopy2 from "../View/User/UserCopy2";
import WorldCupConfig from "../View/WorldCup/WorldCupConfig";
import SpringFestivalRank from "../View/SpringFestival/SpringFestivalRank";
import ValentineRank from "../View/Valentine/ValentineRank";
import VersionMail from "../View/Mail/VersionMail";
import RankView from "../View/Rank/RankView";
import {
  HomeOutlined, MailOutlined,
  UserOutlined,
  SettingOutlined, UsergroupAddOutlined,
  FieldTimeOutlined, AppleOutlined,
  DatabaseOutlined, CopyOutlined,
  EditOutlined,  DotChartOutlined,
} from "@ant-design/icons";

export default function DefaultRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<Login/>}/>
        <Route path={'/home'} element={<Default/>}/>
        <Route path={'/mail'} element={<MailTable/>}/>
        <Route path={'/usermail'} element={<UserMailTable/>}/>
        <Route path={'/usercmd'} element={<UserCmdMailTable/>}/>
        <Route path={'/addadmin'} element={<AddAdmin/>}/>
        <Route path={'/markservertime'} element={<MarkSeverTime/>}/>
        <Route path={'/notfound'} element={<NotFound/>}/>
        <Route path={'/historyorders'} element={<FindHistoryOrders/>}/>
        <Route path={'/markpassword'} element={<MarkPassword/>}/>
        <Route path={'/userinfo'} element={<UserInfo/>}/>
        <Route path={'/markversion'} element={<MarkVersion/>}/>
        <Route path={'/halloweenrank'} element={<HalloweenRank/>}/>
        <Route path={'/halloweenuserrank'} element={<HalloweenUserRank/>}/>
        <Route path={'/halloweenuserdel'} element={<RankUserDel/>}/>
        <Route path={'/refundhistory'} element={<FindRefundHistory/>}/>
        <Route path={'/markaccountmenu'} element={<MarkAccountMenu/>}/>
        <Route path={'/usermail2'} element={<NewUserMailTable/>}/>
        <Route path={'/usercopy2'} element={<UserCopy2/>}/>
        <Route path={'/crossusercopy2'} element={<CrossUserCopy2/>}/>
        <Route path={'/worldcup'} element={<WorldCupConfig/>}/>
        <Route path={'/lightdarkrank'} element={<SpringFestivalRank/>}/>
        <Route path={'/valentinerank'} element={<ValentineRank/>}/>
        <Route path={'/versionmail'} element={<VersionMail/>}/>
        <Route path={'/rankview'} element={<RankView/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export const MenuList = [
  {
    key: '1',
    icon: <HomeOutlined/>,
    label: 'Home',
    path: '/home',
    menuIdx: 1,
  },
  {
    key: 'sub1',
    icon: <MailOutlined/>,
    label: 'Mail',
    path: "/Mail",
    menuIdx: 200,
    items: [
      {
        key: '201',
        label: 'Sys Mail',
        path: "/mail",
        icon: <MailOutlined/>,
        menuIdx: 201,
      },
      /*{
        key: '202',
        label: 'User Mail',
        path: "/usermail",
        icon: <MailOutlined/>,
        menuIdx: 202,
      },*/
      {
        key: '203',
        label: 'User Cmd',
        path: "/usercmd",
        icon: <MailOutlined/>,
        menuIdx: 203,
      },
      {
        key: '204',
        label: 'User Mail 2',
        path: "/usermail2",
        icon: <MailOutlined/>,
        menuIdx: 204,
      }, {
        key: '205',
        label: 'Version Mail',
        path: "/versionmail",
        icon: <MailOutlined/>,
        menuIdx: 205,
      }
    ],
  },
  {
    key: 'sub2',
    icon: <UserOutlined/>,
    label: 'User',
    path: "/User",
    menuIdx: 300,
    items: [
      {
        key: '301',
        label: 'User Info',
        path: "/userinfo",
        icon: <DatabaseOutlined/>,
        menuIdx: 301,
      },
      {
        key: '302',
        label: 'User Copy 2',
        path: '/usercopy2',
        icon: <CopyOutlined/>,
        menuIdx: 302,
      },
      {
        key: '303',
        label: 'Cross User Copy 2',
        path: '/crossusercopy2',
        icon: <CopyOutlined/>,
        menuIdx: 303,
      },
    ],
  },
  {
    key: 'sub3',
    icon: <AppleOutlined/>,
    label: 'App Store',
    path: '/App Store',
    menuIdx: 900,
    items: [{
      key: '901',
      label: 'History Orders',
      path: "/historyorders",
      icon: <AppleOutlined/>,
      menuIdx: 901,
    }, {
      key: '902',
      label: 'Refund History',
      path: "/refundhistory",
      icon: <AppleOutlined/>,
      menuIdx: 902,
    }]
  },
  {
    key: 'sub100',
    icon: <SettingOutlined/>,
    label: 'Setting',
    path: '/Setting',
    menuIdx: 1000,
    items: [{
      key: '1001',
      label: 'Add Admin',
      path: "/addadmin",
      icon: <UsergroupAddOutlined/>,
      menuIdx: 1001,
    }, {
      key: '1002',
      label: 'Mark Server Time',
      path: "/markservertime",
      icon: <FieldTimeOutlined/>,
      menuIdx: 1002,
    }, {
      key: '1003',
      label: 'Mark Password',
      path: "/markpassword",
      icon: <FieldTimeOutlined/>,
      menuIdx: 1003,
    }, {
      key: '1004',
      label: 'Mark Version',
      path: "/markversion",
      icon: <EditOutlined/>,
      menuIdx: 1004,
    }, {
      key: '1005',
      label: 'Mark Account Menus',
      path: "/markaccountmenu",
      icon: <EditOutlined/>,
      menuIdx: 1005,
    }]
  },{
    key: 'sub150',
    icon: <DotChartOutlined/>,
    label: 'Light Dark RANK',
    path: '/Light Dark RANK',
    menuIdx: 1500,
    items: [
      {
        key: '1501',
        label: 'Light Dark RANK',
        path: '/lightdarkrank',
        icon: <DotChartOutlined/>,
        menuIdx: 1501,
      },
    ],
  },{
    key: 'sub170',
    icon: <DotChartOutlined/>,
    label: 'RANK VIEW',
    path: '/RANK VIEW',
    menuIdx: 1700,
    items: [
      {
        key: '1701',
        label: 'RANK VIEW',
        path: '/rankview',
        icon: <DotChartOutlined/>,
        menuIdx: 1701,
      },
    ],
  },
]

/*

  {
    key: 'sub110',
    icon: <BarChartOutlined/>,
    label: 'Hallow RANK',
    path: '/Hallow RANK',
    menuIdx: 1100,
    items: [{
      key: '1101',
      label: 'Halloween Rank',
      path: '/halloweenrank',
      icon: <BarChartOutlined/>,
      menuIdx: 1101,
    }, {
      key: '1102',
      label: 'Halloween User Rank',
      path: '/halloweenuserrank',
      icon: <BarChartOutlined/>,
      menuIdx: 1102,
    }, {
      key: '1103',
      label: 'Halloween User DELETE',
      path: '/halloweenuserdel',
      icon: <BarChartOutlined/>,
      menuIdx: 1103,
    },
    ]
  },
  {
    key: 'sub120',
    icon: <TeamOutlined/>,
    label: 'World Cup',
    path: '/World Cup',
    menuIdx: 1200,
    items: [{
      key: '1201',
      label: 'World Cup Config',
      path: '/worldcup',
      icon: <TeamOutlined/>,
      menuIdx: 1201,
    }]
  },
  {
    key: 'sub130',
    icon: <DotChartOutlined/>,
    label: 'IceAndSnowFestival RANK',
    path: '/IceAndSnowFestival RANK',
    menuIdx: 1300,
    items: [
      {
        key: '1301',
        label: 'IceAndSnowFestival RANK',
        path: '/icerank',
        icon: <DotChartOutlined/>,
        menuIdx: 1301,
      }, {
        key: '1302',
        label: 'IceAndSnowFestival DEL',
        path: '/icedel',
        icon: <DeleteOutlined/>,
        menuIdx: 1302,
      },
    ],
  },
  {
    key: 'sub140',
    icon: <DotChartOutlined/>,
    label: 'Dragon RANK',
    path: '/Dragon RANK',
    menuIdx: 1400,
    items: [
      {
        key: '1401',
        label: 'Dragon RANK',
        path: '/dragonrank',
        icon: <DotChartOutlined/>,
        menuIdx: 1401,
      },
    ],
  },

    {
    key: 'sub160',
    icon: <DotChartOutlined/>,
    label: 'Valentine RANK',
    path: '/Valentine RANK',
    menuIdx: 1600,
    items: [
      {
        key: '1601',
        label: 'Valentine RANK',
        path: '/valentinerank',
        icon: <DotChartOutlined/>,
        menuIdx: 1601,
      },
    ],
  },
 */

export const MenuItems = {
  menus: [{
    key: '1',
    title: 'Home',
  }, {
    key: '201',
    title: 'Sys Mail',
  },/* {
    key: '202',
    title: 'User Mail',
  },*/ {
    key: '203',
    title: 'User Cmd',
  }, {
    key: '204',
    title: 'User Mail 2',
  }, {
    key: '205',
    title: 'Version Mail',
  }, {
    key: '301',
    title: 'User Info',
  }, {
    key: '302',
    title: 'User Copy 2',
  }, {
    key: '303',
    title: 'Cross User Copy 2',
  }, {
    key: '901',
    title: 'History Orders',
  }, {
    key: '1001',
    title: 'Add Admin',
  }, {
    key: '1002',
    title: 'Mark Server Time',
  }, {
    key: '1003',
    title: 'Mark Password',
  }, {
    key: '1004',
    title: 'Mark Version',
  }, {
    key: '1005',
    title: 'Mark Account Menus',
  },
    {
      key: '902',
      title: 'Refund History',
    }, {
      key: '1501',
      title: 'Light Dark Rank',
    },
    /*
    {
    key: '1101',
    title: 'Halloween Rank',
  }, {
    key: '1102',
    title: 'Halloween User Rank',
  }, {
    key: '1103',
    title: 'Halloween User DELETE',
  }, , {
    key: '1201',
    title: 'World Cup',
  }, {
    key: '1301',
    title: 'IceAndSnowFestival RANK',
  }, {
    key: '1302',
    title: 'IceAndSnowFestival DEL',
  }, {
    key: '1401',
    title: 'Dragon RANK',
  },, {
    key: '1601',
    title: 'Valentine Rank',
  }
  */
  ]

}

export function GetMenus(values: string[]) {
  let menus: string[] = [];
  for (let i = 0; i < values.length; i++) {
    for (let z = 0; z < MenuList.length; z++) {

      if (MenuList[z].key.indexOf('sub') >= 0) {
        const childs = MenuList[z].items as Array<any>;
        for (let y = 0; y < childs.length; y++) {
          const idx = String(childs[y].menuIdx);
          if (idx === values[i]) {
            const idx1 = String(MenuList[z].menuIdx);
            if (menus.indexOf(idx) < 0) {
              menus.push(idx);
            }
            if (menus.indexOf(idx1) < 0) {
              menus.push(idx1);
            }
          }
        }
      } else {
        const idx = String(MenuList[z].menuIdx);
        if (idx === values[i]) {
          if (menus.indexOf(idx) < 0) {
            menus.push(idx);
          }
        }
      }
    }
  }
  return menus;
}
