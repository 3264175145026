import React, {Component, ReactNode} from 'react';
import {ProCoreActionType, ProTable} from "@ant-design/pro-components";
import {Button, Space, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import {UserMailTableColumns, UserJsonKey, UserMailTableDataSource, UserMailItem} from './Model';
import AddUserMailForm from './AddUserMailForm';
import DefaultTemplate from '../../Template/DefaultTemplate'
import {LoadFile} from "../../Utils/util";
import PostServer from "../../Utils/http";
import {MethodDeleteRows, MethodDelRow, MethodParseCouldUserMail, MethodUserMail} from "../../Utils/method";
import {MessageError, MessageInfo, MessageSuccess} from "../../Template/MessageNet";

export default class UserMailTable extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      json: {},
      call: this.Call,
      isOpen: false,
    }
    this.CreateUserMail = this.CreateUserMail.bind(this);
    this.ImportFile = this.ImportFile.bind(this);
    this.Call = this.Call.bind(this);
    this.SelectDelRows = this.SelectDelRows.bind(this);
  }

  Call = (value: any) => {
    this.setState({visible: false})
    this.useTableRef.current.reload();
  }

  GetSource() {
    UserMailTableDataSource.sort((a: any, b: any) => {
      return b.key - a.key;
    })
    let tableSource: UserMailItem[] = JSON.parse(JSON.stringify(UserMailTableDataSource));
    return tableSource;
  }

  CreateUserMail() {
    this.setState({visible: true, json: UserJsonKey, call: this.Call})
  }

  LoadColumns() {
    let columns = JSON.parse(JSON.stringify(UserMailTableColumns));
    columns.push({
      title: "操作",
      width: 160,
      key: "option",
      valueType: "option",
      align: 'center',
      fixed: "right",
      render: (_: ReactNode, row: UserMailItem, index: number, action: any) =>
        [<a key="link_del" onClick={() => this.DelRow(row, index, action)}>删除</a>,
          <a key="link_cpy" onClick={() => this.CopyRow(row, index, action)}>复制</a>,
          <a key="link_send" onClick={() => this.SendParse(row, index, action)}>发布</a>],
    });
    return columns;
  }

  CopyRow(row: UserMailItem, index: number, action: ProCoreActionType<any>) {
    this.setState({visible: true, json: row, call: this.Call})
  };

  async DelRow(row: UserMailItem, index: number, action: ProCoreActionType<any>) {
    this.setState({isOpen: true, visible: false});
    const req = await PostServer(MethodDelRow, JSON.stringify({type: 2, key: row.key}));
    this.setState({isOpen: false});
    if (req === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (req.err_code !== 0) {
      MessageError('Error', `Server to Code:${req.err_code}`);
      return;
    }
    UserMailTableDataSource.splice(index, 1);
    action?.reload();
  };

  async SendParse(row: UserMailItem, index: number, action: any) {
    if (row.status === 'online') {
      MessageInfo('Info', '此条数据已发布,需要重新发布,请复制创建!');
      return;
    }
    this.setState({isOpen: true, visible: false});
    const data = await PostServer(MethodParseCouldUserMail, JSON.stringify({
      key: row.key,
    }));
    this.setState({isOpen: false});
    if (data === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (data.err_code !== 0) {
      MessageError('Error', `Server to Code:${data.err_code}`);
      return;
    }
    UserMailTableDataSource.forEach((item) => {
      if (item.key === row.key) {
        item.status = 'online';
      }
    });
    action.reload(index);
    MessageSuccess('Success', '发布成功!');
  };

  ImportFile(file: any) {
    LoadFile(file, (data: any) => {
      this.setState({visible: true, json: data, call: this.Call})
    });
  }

  async componentDidMount() {
    const data = await PostServer(MethodUserMail, '');
    if (data === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (data.err_code !== 0) {
      MessageError('Error', `Server to Code:${data.err_code}`);
      return;
    }
    console.log(data.mail);
    UserMailTableDataSource.splice(0, UserMailTableDataSource.length);
    UserMailTableDataSource.push(...data.mail);
    this.useTableRef.current.reload();
  }

  async SelectDelRows(selectedRowKeys: (Number | string)[], selectedRows: any) {
    this.setState({isOpen: true, visible: false});
    const req = await PostServer(MethodDeleteRows, JSON.stringify({type: 2, keys: selectedRowKeys}));
    this.setState({isOpen: false});
    if (req === undefined) {
      console.log('请求失败!');
      return;
    }
    if (req.err_code !== 0) {
      console.log(`Server to Code:${req.err_code}`);
      return;
    }
    for (let i = 0; i < UserMailTableDataSource.length; i++) {
      for (let row of selectedRowKeys) {
        if (UserMailTableDataSource[i].key === row) {
          UserMailTableDataSource.splice(i, 1)
          i--;
          break;
        }
      }
    }
    this.useTableRef.current.reload();
  }


  useTableRef: any = {};

  indexPage: string[] = ['sub1', '202'];

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <ProTable
          actionRef={this.useTableRef}
          rowKey="key"
          columns={this.LoadColumns()}
          search={false}
          options={false}
          headerTitle="User Mail"
          scroll={{
            x: 1300
          }}
          pagination={{
            pageSize: 20,
          }}
          request={async () => {
            const data = this.GetSource()
            return {
              data: data,
              success: true,
              total: data.length,
            };
          }}
          toolBarRender={() => [
            <>
              <Upload action="" accept="json/plain" beforeUpload={this.ImportFile}
                      showUploadList={false}>
                <Button icon={<UploadOutlined/>}>加载JSON</Button>
              </Upload>
              <Button type="primary" key="primary" onClick={this.CreateUserMail}>
                创建邮件
              </Button>
            </>
          ]}
          rowSelection={{}}
          tableAlertOptionRender={({selectedRowKeys, selectedRows, onCleanSelected}) => {
            return <Space>
              <a onClick={() => this.SelectDelRows(selectedRowKeys, selectedRows)}>批量删除</a>
              <a onClick={onCleanSelected}>取消选择</a>
            </Space>
          }}
        />
        <AddUserMailForm visible={this.state.visible} json={this.state.json} call={this.state.call}/>

      </DefaultTemplate>
    );
  }
}