import React from 'react'
import {Form, Input, Button} from 'antd'
import {UserOutlined, LockOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import '../../css/style_app.css'
import PostServer from "../../Utils/http";
import {LoginData} from "../../Local/local";
import {MethodLogin} from "../../Utils/method";
import {MessageError} from "../../Template/MessageNet";
import 'rc-texty/assets/index.css'


export default function Login() {

  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    const json = JSON.stringify({
      account: values.account,
      password: values.password,
    });
    const data = await PostServer(MethodLogin, json);
    if (data === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (data.err_code !== 0) {
      MessageError('Error', `Server to Code:${data.err_code}`);
      return;
    }
    LoginData.Token = data.token;
    LoginData.MenuIdx = data.menu_idx;
    LoginData.Account = data.account;
    navigate('home');
  }
  return (
    <div className={"login-div-center"}>
      <Form
        layout="horizontal"
        onFinish={onFinish}
        style={{
          width: '35vh'
        }}
      >
        <Form.Item
          name="account"
          label={'账号:'}
          rules={[
            {
              required: true,
              message: 'Please input your Account!',
            },
            {
              type: 'string',
              min: 5,
              message: 'Please input your string right!',
            }
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Account"/>
        </Form.Item>
        <Form.Item
          name="password"
          label={'密码:'}
          rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
            {
              type: 'string',
              min: 6,
            }
          ]}
        >
          <Input
            prefix={<LockOutlined/>}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Sig in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}


