import React, {Component} from "react";
import {notification, Typography} from "antd";
import {CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined} from '@ant-design/icons';


export const MessageSuccess = (title: string, content: string) => {
  notification.open({
    message: <Typography.Title level={4} style={{color: '#008B45'}}>{title}</Typography.Title>,
    description: content,
    duration: 1,
    icon: <CheckCircleOutlined style={{color: '#008B45'}}/>,
    maxCount: 3,
  });
}

export const MessageError = (title: string, content: string) => {
  notification.open({
    message: <Typography.Title level={4} style={{color: '#FF0000'}}>{title}</Typography.Title>,
    description: content,
    duration: 2,
    icon: <CloseCircleOutlined style={{color: '#FF0000'}}/>,
    maxCount: 3,
  });
}

export const MessageInfo = (title: string, content: string) => {
  notification.open({
    message: <Typography.Title level={4} style={{color: '#00C5CD'}}>{title}</Typography.Title>,
    description: content,
    duration: 1,
    icon: <ExclamationCircleOutlined style={{color: '#00EEEE'}}/>,
    maxCount: 3,
  });
}