import {LoginData} from "../Local/local";
import {LoadPublicConfigSync} from "./util";
import {message} from "antd";

export default async function PostServer(method, values) {
  try {
    let config = await LoadPublicConfigSync()
    if (config === undefined) {
      config = {
        Server: window.location.toString()
      }
    }

    const url = `${config.Server}${method}`
    console.log(url)
    const response = await fetch(url, {
      method: 'POST',
      body: values,
      headers: {'Token': LoginData.Token},
      timeOut: 1000 * 300,
    });
    if (!response.ok) {
      console.log(`请求失败! ${response.status}`);
      return undefined;
    }
    const result = await response.json();
    return result;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}


export async function PostXlsx(method, values, fileName) {
  try {
    let config = await LoadPublicConfigSync()
    if (config === undefined) {
      config = {
        Server: window.location.toString()
      }
    }
    const url = `${config.Server}${method}`
    fetch(url, {
      method: 'POST',
      body: values,
      responseType: 'arraybuffer',
      headers: {'Token': LoginData.Token},
    }).then(res => {
      return res.arrayBuffer();
    }).then(arraybuffer => {
      if (arraybuffer.byteLength < 1) {
        message.error('无数据')
        return
      }
      console.log(arraybuffer);
      let bl = new Blob([arraybuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(bl);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    })
  } catch (err) {
    console.log(err);
  }
}