export function MailConvertLanguage(mail) {
  let dataJson = {};
  const keys = Object.keys(mail);
  for (let idx = 0; idx < keys.length; idx++) {
    let keyName = keys[idx];
    let keyData = mail[keyName];

    if (keyName.indexOf('content') >= 0 || keyName.indexOf('name') >= 0) {
      const keyTemp = keyName;
      const newKey = keyTemp.replace('_', '-')
      dataJson[newKey] = keyData;

    } else if (keyName.indexOf('reward') >= 0) {
      if (keyData === undefined || keyData.length < 1) {
        dataJson[keyName] = []
      } else {
        console.log(keyData)
        dataJson[keyName] = JSON.parse(keyData);
      }
    }
  }
  console.log(dataJson);
  return dataJson;
}

export function LoadFile(file, call) {
  const reader = new FileReader();
  reader.readAsText(file);
  reader.onload = (result) => {
    const jsonStr = result.target?.result;
    const data = JSON.parse(jsonStr);
    call(data);
  }
}

export function LoadPublicConfig(call) {
  try {
    fetch(`${process.env.PUBLIC_URL}/config.json`)
      .then(resp => resp.json())
      .then((data) => {
        call(data);
      });
  } catch (e) {
    const data ={
      Server:window.location.toString(),
      Platform:"(未知版本)未找到Public->config.json"
    }
    call(data);
  }
}

export async function LoadPublicConfigSync() {
  try {
    let resp = await fetch(`${process.env.PUBLIC_URL}/config.json`);
    let data = await resp.json();
    return data;
  }catch (e){
    return undefined;
  }
}