import React, {Component} from "react"
import DefaultTemplate from "../../Template/DefaultTemplate";
import {Button, Form, Input, Transfer} from "antd";
import '../../css/style_app.css'
import {UserOutlined} from "@ant-design/icons";
import {TransferDirection} from "antd/lib/transfer";
import {GetMenus, MenuItems} from "../../Template/DefaultRoutes";
import PostServer from "../../Utils/http";
import {MethodMarkAccountMenus} from "../../Utils/method";
import {MessageError, MessageSuccess} from "../../Template/MessageNet";


export default class MarkAccountMenu extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      targetKeys: [],
      isOpen: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }

  async onFinish(values: any) {
    this.setState({isOpen: true});
    const req = await PostServer(MethodMarkAccountMenus, JSON.stringify({
      account: values.account,
      menus: GetMenus(values.menus)
    }))
    this.setState({isOpen: false});
    if (req === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (req.err_code !== 0) {
      MessageError('Error', `Server to Code:${req.err_code}`);
      return;
    }
    MessageSuccess('Success', 'Update Success');
  }

  onChange(nextTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) {
    this.setState({targetKeys: nextTargetKeys});
  }

  indexPage: string[] = ['sub100', '1005']

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <div className={"login-div-center"}>
          <Form
            layout="horizontal"
            onFinish={this.onFinish}
            style={{
              width: '600px'
            }}
          >
            <Form.Item
              name="account"
              label={'账号:'}
              rules={[
                {
                  required: true,
                  message: 'Please input your Account!',
                },
                {
                  type: 'string',
                  min: 6,
                }
              ]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Account"/>
            </Form.Item>
            <Form.Item
              name='menus'>
              <Transfer
                titles={['菜单选项', '应用菜单']}
                dataSource={MenuItems.menus}
                onChange={this.onChange}
                targetKeys={this.state.targetKeys}
                render={(item => item.title)}
                listStyle={{width: '100%', height: '50%'}}
                locale={{itemUnit: '项', itemsUnit: '项'}}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Mark Account Menu
              </Button>
            </Form.Item>
          </Form>
        </div>
      </DefaultTemplate>
    );
  }
}