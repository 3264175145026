import React, {Component, ReactNode} from 'react';
import {ProCoreActionType, ProTable} from "@ant-design/pro-components";
import {Button, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import DefaultTemplate from '../../Template/DefaultTemplate';
import AddUserCmdForm from "./AddUserCmdForm";
import {UserCmdTableItem, UserCmdJsonKey, UserCmdTableColumns, UserCmdTableDataSource} from "./Model";
import {LoadFile} from "../../Utils/util";
import PostServer from "../../Utils/http";
import {MethodDelRow, MethodParseCouldUserCmd, MethodUserCmd} from "../../Utils/method";
import {MessageError, MessageInfo, MessageSuccess} from "../../Template/MessageNet";

export default class UserCmdMailTable extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      json: {},
      call: this.Call,
      isOpen: false,
    }
    this.CreateUserCmd = this.CreateUserCmd.bind(this);
    this.ImportFile = this.ImportFile.bind(this);
    this.Call = this.Call.bind(this);
  }

  Call = (value: any) => {
    this.setState({visible: false});
    this.useTableRef.current.reload();
  }

  GetSource() {
    UserCmdTableDataSource.sort((a: any, b: any) => {
      return b.key - a.key;
    });
    let tableSource: UserCmdTableItem[] = JSON.parse(JSON.stringify(UserCmdTableDataSource));
    return tableSource;
  }

  CreateUserCmd() {
    this.setState({visible: true, json: UserCmdJsonKey, call: this.Call})
  }

  LoadColumns() {
    let columns = JSON.parse(JSON.stringify(UserCmdTableColumns));
    columns.push({
      title: "操作",
      width: 160,
      key: "option",
      valueType: "option",
      align: 'center',
      fixed: "right",
      render: (_: ReactNode, row: UserCmdTableItem, index: number, action: any) =>
        [<a key="link_del" onClick={() => this.DelRow(row, index, action)}>删除</a>,
          <a key="link_send" onClick={() => this.SendParse(row, index, action)}>发布</a>],
    });
    return columns;
  }

  async DelRow(row: UserCmdTableItem, index: number, action: ProCoreActionType<any>) {
    this.setState({isOpen: true, visible: false});
    const req = await PostServer(MethodDelRow, JSON.stringify({type: 3, key: row.key}));
    this.setState({isOpen: false});
    if (req === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (req.err_code !== 0) {
      MessageError('Error', `Server to Code:${req.err_code}`);
      return;
    }
    UserCmdTableDataSource.splice(index, 1);
    action?.reload();
  };

  async SendParse(row: UserCmdTableItem, index: number, action: any) {
    if (row.status === 'online') {
      MessageInfo('Info', '已发布!');
      return;
    }
    this.setState({isOpen: true, visible: false});
    const data = await PostServer(MethodParseCouldUserCmd, JSON.stringify({key: row.key}));
    this.setState({isOpen: false});
    if (data === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (data.err_code !== 0) {
      MessageError('Error', `Server to Code:${data.err_code}`);
      return;
    }
    UserCmdTableDataSource.forEach((item) => {
      if (item.key === row.key) {
        item.status = 'online';
      }
    });
    action.reload(index);
    MessageSuccess('Success', '发布成功!');
  };

  ImportFile(file: any) {
    LoadFile(file, (data: any) => {
      this.setState({visible: true, json: data, call: this.Call});
    })
  }

  async componentDidMount() {
    const data = await PostServer(MethodUserCmd, '');
    if (data === undefined) {
      console.log('请求失败!');
      return;
    }
    if (data.err_code !== 0) {
      console.log(`Server to Code:${data.err_code}`);
      return;
    }
    UserCmdTableDataSource.splice(0, UserCmdTableDataSource.length);
    UserCmdTableDataSource.push(...data.cmds);
    this.useTableRef.current.reload();
  }

  useTableRef: any = {};

  indexPage: string[] = ['sub1', '203'];

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <ProTable
          actionRef={this.useTableRef}
          rowKey="key"
          columns={this.LoadColumns()}
          search={false}
          options={false}
          headerTitle="User Cmd"
          scroll={{
            x: 1300
          }}
          pagination={{
            pageSize: 20,
          }}
          request={async () => {
            const data = this.GetSource()
            return {
              data: data,
              success: true,
              total: data.length,
            };
          }}
          toolBarRender={() => [
            <>
              <Upload action="" accept="json/plain" beforeUpload={this.ImportFile}
                      showUploadList={false}>
                <Button icon={<UploadOutlined/>}>加载JSON</Button>
              </Upload>
              <Button type="primary" key="primary" onClick={this.CreateUserCmd}>
                创建GM指令
              </Button>
            </>
          ]}
        />
        <AddUserCmdForm visible={this.state.visible} json={this.state.json} call={this.state.call}/>

      </DefaultTemplate>
    );
  }
}