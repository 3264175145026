import React, {Component, ReactNode} from 'react';
import {ProCoreActionType, ProTable} from "@ant-design/pro-components";
import {Button, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import {VersionJsonKey, VersionTableColumns, VersionTableDataSource, VersionTableListItem} from './Model';
import DefaultTemplate from "../../Template/DefaultTemplate";
import {LoadFile} from "../../Utils/util";
import PostServer from "../../Utils/http";
import {
  MethodDelRow,
  MethodMarkSysMail,
  MethodParseCouldSysMail,
  MethodSysMail,
  MethodVersionMail, MethodVersionMailPushCloud
} from "../../Utils/method";
import {MessageError, MessageInfo, MessageSuccess} from "../../Template/MessageNet";
import VersionMailAdd from "./VersionMailAdd";

export default class VersionMail extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      json: {},
      call: this.Call,
      isOpen: false,
    }
    this.CreateMail = this.CreateMail.bind(this);
    this.ImportFile = this.ImportFile.bind(this);
    this.Call = this.Call.bind(this);
    this.DelRow = this.DelRow.bind(this);
  }

  Call = (value: any) => {
    this.setState({visible: false})
    this.useTableRef.current.reload();
  }

  GetSource() {
    VersionTableDataSource.sort((a: any, b: any) => {
      return b.key - a.key;
    })
    let tableSource: VersionTableListItem[] = JSON.parse(JSON.stringify(VersionTableDataSource));
    return tableSource;
  }

  CreateMail() {
    this.setState({visible: true, json: VersionJsonKey, call: this.Call})
  }

  LoadColumns() {
    let columns = JSON.parse(JSON.stringify(VersionTableColumns));
    columns.push({
      title: "操作",
      width: 250,
      key: "option",
      valueType: "option",
      align: 'center',
      fixed: "right",
      render: (_: ReactNode, row: VersionTableListItem, index: number, action: any) =>
        [
          <a key="link_del" onClick={() => this.DelRow(row, index, action)}>删除</a>,
          <a key="link_cpy" onClick={() => this.CopyRow(row, index, action)}>复制</a>,
          <a key="link_send" onClick={() => this.SendRow(row, index, action)}>发布</a>],
    });
    return columns;
  }

  async DelRow(row: VersionTableListItem, index: number, action: ProCoreActionType<any>) {
    this.setState({isOpen: true, visible: false});
    const req = await PostServer(MethodDelRow, JSON.stringify({type: 5, key: row.id}));
    this.setState({isOpen: false});
    if (req === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (req.err_code !== 0) {
      MessageError('Error', 'Server to Code:${req.err_code}');
      return;
    }
    VersionTableDataSource.splice(index, 1);
    action?.reload();
  };

  async SendRow(row: VersionTableListItem, index: number, action: any) {
    if (row.status === 'online') {
      MessageInfo('Info', '此数据已发布,需要重新发布,请复制/创建!');
      return;
    }
    this.setState({isOpen: true});
    const data = await PostServer(MethodVersionMailPushCloud, JSON.stringify({
      id: row.id,
    }));
    this.setState({isOpen: false});
    if (data === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (data.err_code !== 0) {
      MessageError('Error', `Server to Code:${data.err_code}`);
      return;
    }
    VersionTableDataSource.forEach((item) => {
      if (item.id === row.id) {
        item.status = 'online';
      }
    });
    action.reload(index);
    MessageSuccess('Success', '发布成功!');
  };

  CopyRow(row: VersionTableListItem, index: number, action: ProCoreActionType<any>) {
    const data = JSON.parse(JSON.stringify(row));
    this.setState({visible: true, json: data, call: this.Call})
  };

  ImportFile(file: any) {
    LoadFile(file, (data: any) => {
      this.setState({visible: true, json: data, call: this.Call})
    });
  }

  async componentDidMount() {
    const data = await PostServer(MethodVersionMail, '');
    if (data === undefined) {
      console.log('请求失败!')
      return;
    }
    if (data.err_code !== 0) {
      console.log(`Server to Code:${data.err_code}`)
      return;
    }
    VersionTableDataSource.splice(0, VersionTableDataSource.length);
    VersionTableDataSource.push(...data.mail);
    this.useTableRef.current.reload();
  }

  useTableRef: any = {};
  indexPage: string[] = ['sub1', '205']

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <ProTable<VersionTableListItem>
          actionRef={this.useTableRef}
          rowKey={'id'}
          columns={this.LoadColumns()}
          search={false}
          options={false}
          headerTitle="Version Mail"
          scroll={{
            x: 1300
          }}
          pagination={{
            pageSize: 20,
          }}
          request={async () => {
            const dataSource = this.GetSource()
            return {
              data: dataSource,
              success: true,
              total: dataSource.length,
            };
          }}
          toolBarRender={() => [
            <>
              <Upload action="" accept="json/plain" beforeUpload={this.ImportFile}
                      showUploadList={false}>
                <Button icon={<UploadOutlined/>}>加载JSON</Button>
              </Upload>
              <Button type="primary" key="primary" onClick={this.CreateMail}>
                创建邮件
              </Button>
            </>
          ]}
        />
        <VersionMailAdd visible={this.state.visible} json={this.state.json} call={this.state.call}/>
      </DefaultTemplate>
    );
  }
}