import {ProColumns} from "@ant-design/pro-components";
import moment from "moment";

export type TableListItem = {
  key: number;
  id: number;
  status: string;
  max_version: string;
  min_version: string;
  platform: string;
  start_time: string;
  stop_time: string;
  max_created_at: string;
  reward: string;
  name_cn: string;
  name_de: string;
  name_en: string;
  name_fr: string;
  name_id: string;
  name_ja: string;
  name_ko: string;
  name_pt: string;
  name_th: string;
  name_vi: string;
  name_zh: string;
  content_cn: string;
  content_de: string;
  content_en: string;
  content_fr: string;
  content_id: string;
  content_ja: string;
  content_ko: string;
  content_pt: string;
  content_th: string;
  content_vi: string;
  content_zh: string;
};

export const JsonKey = {
  id: 1,
  status: 'error',
  max_version: '',
  min_version: '',
  platform: '',
  start_time: moment().format('YYYY-MM-DD HH:mm:ss'),
  stop_time: moment().format('YYYY-MM-DD HH:mm:ss'),
  reward: '',
  max_created_at: moment('2099-12-30 00:00:00').format('YYYY-MM-DD HH:mm:ss'),
  des: '',
  name_cn: '',
  name_de: '',
  name_en: '',
  name_fr: '',
  name_id: '',
  name_ja: '',
  name_ko: '',
  name_pt: '',
  name_th: '',
  name_vi: '',
  name_zh: '',
  content_cn: '',
  content_de: '',
  content_en: '',
  content_fr: '',
  content_id: '',
  content_ja: '',
  content_ko: '',
  content_pt: '',
  content_th: '',
  content_vi: '',
  content_zh: '',
}

export const TableColumns: ProColumns<TableListItem>[] = [
  {
    title: "ID",
    width: 40,
    dataIndex: "id",
    align: 'center',
    fixed: "left",
  },
  {
    title: "状态",
    width: 100,
    dataIndex: "status",
    align: 'center',
    fixed: "left",
    initialValue: 'error',
    valueEnum: {
      online: {text: '发布成功', status: 'Success'},
      error: {text: '未发布', status: 'Error'},
    },
  },
  {
    title: "最大版本号",
    width: 100,
    dataIndex: "max_version",
  },
  {
    title: "最小版本号",
    width: 100,
    dataIndex: "min_version",
  },
  {
    title: "平台",
    width: 100,
    dataIndex: "platform",
    valueEnum: {
      Android: {text: 'Android'},
      iOS: {text: 'iOS'}
    }
  },
  {
    title: "开始时间",
    width: 180,
    dataIndex: "start_time",
  },
  {
    title: "结束时间",
    width: 180,
    dataIndex: "stop_time",
  },
  {
    title: "奖励",
    width: 200,
    dataIndex: "reward",
    ellipsis: true,
  },
  {
    title: "创号时间",
    width: 180,
    dataIndex: "max_created_at",
  },
  {
    title: "中文标题",
    width: 120,
    dataIndex: "name_cn",
    ellipsis: true,
  },
  {
    title: "德语标题",
    width: 120,
    dataIndex: "name_de",
    ellipsis: true,
  },
  {
    title: "法语标题",
    width: 120,
    dataIndex: "name_fr",
    ellipsis: true,
  },
  {
    title: "印尼标题",
    width: 120,
    dataIndex: "name_id",
    ellipsis: true,
  },
  {
    title: "日文标题",
    width: 120,
    dataIndex: "name_ja",
    ellipsis: true,
  },
  {
    title: "韩语标题",
    width: 120,
    dataIndex: "name_ko",
    ellipsis: true,
  },
  {
    title: "葡萄牙标题",
    width: 120,
    dataIndex: "name_pt",
    ellipsis: true,
  },
  {
    title: "泰语标题",
    width: 120,
    dataIndex: "name_th",
    ellipsis: true,
  },
  {
    title: "越南标题",
    width: 120,
    dataIndex: "name_vi",
    ellipsis: true,
  },
  {
    title: "繁中标题",
    width: 120,
    dataIndex: "name_zh",
    ellipsis: true,
  },
  {
    title: "中文内容",
    width: 120,
    dataIndex: "content_cn",
    ellipsis: true,
  },
  {
    title: "德语内容",
    width: 120,
    dataIndex: "content_de",
    ellipsis: true,
  },
  {
    title: "英语内容",
    width: 120,
    dataIndex: "content_en",
    ellipsis: true,
  },
  {
    title: "法语内容",
    width: 120,
    dataIndex: "content_fr",
    ellipsis: true,
  },
  {
    title: "印尼内容",
    width: 120,
    dataIndex: "content_id",
    ellipsis: true,
  },
  {
    title: "日本内容",
    width: 120,
    dataIndex: "content_ja",
    ellipsis: true,
  },
  {
    title: "韩国内容",
    width: 120,
    dataIndex: "content_ko",
    ellipsis: true,
  },
  {
    title: "葡萄牙内容",
    width: 120,
    dataIndex: "content_pt",
    ellipsis: true,
  },
  {
    title: "泰语内容",
    width: 120,
    dataIndex: "content_th",
    ellipsis: true,
  },
  {
    title: "越南内容",
    width: 120,
    dataIndex: "content_vi",
    ellipsis: true,
  },
  {
    title: "繁中内容",
    width: 120,
    dataIndex: "content_zh",
    ellipsis: true,
  },
];

export const LanguageData = [
  {
    key_title: "name_cn",
    key_content: "content_cn",
    title: "中文标题",
    content: "中文内容",
  },
  {
    key_title: "name_en",
    key_content: "content_en",
    title: "英文标题",
    content: "英文内容",
  },
  {
    key_title: "name_ja",
    key_content: "content_ja",
    title: "日文标题",
    content: "日文内容",
  },
  {
    key_title: "name_ko",
    key_content: "content_ko",
    title: "韩文标题",
    content: "韩文内容",
  },
  {
    key_title: "name_vi",
    key_content: "content_vi",
    title: "越南标题",
    content: "越南内容",
  },
  {
    key_title: "name_zh",
    key_content: "content_zh",
    title: "繁中标题",
    content: "繁中内容",
  },
  {
    key_title: "name_id",
    key_content: "content_id",
    title: "印尼标题",
    content: "印尼内容",
  },
  {
    key_title: "name_de",
    key_content: "content_de",
    title: "德语标题",
    content: "德语内容",
  },
  {
    key_title: "name_fr",
    key_content: "content_fr",
    title: "法语标题",
    content: "法语内容",
  },
  {
    key_title: "name_pt",
    key_content: "content_pt",
    title: "葡萄牙标题",
    content: "葡萄牙内容",
  },
  {
    key_title: "name_th",
    key_content: "content_th",
    title: "泰语标题",
    content: "泰语内容",
  }
]

export let TableDataSource: TableListItem[] = [];

// VersionMail Model

export type VersionTableListItem = {
  id: number;
  status: string;
  ios: string;
  android: string,
  reward: string;
  name_cn: string;
  name_de: string;
  name_en: string;
  name_fr: string;
  name_id: string;
  name_ja: string;
  name_ko: string;
  name_pt: string;
  name_th: string;
  name_vi: string;
  name_zh: string;
  content_cn: string;
  content_de: string;
  content_en: string;
  content_fr: string;
  content_id: string;
  content_ja: string;
  content_ko: string;
  content_pt: string;
  content_th: string;
  content_vi: string;
  content_zh: string;
};

export const VersionJsonKey = {
  id: 1,
  status: 'error',
  ios: '',
  android: '',
  reward: '',
  name_cn: '',
  name_de: '',
  name_en: '',
  name_fr: '',
  name_id: '',
  name_ja: '',
  name_ko: '',
  name_pt: '',
  name_th: '',
  name_vi: '',
  name_zh: '',
  content_cn: '',
  content_de: '',
  content_en: '',
  content_fr: '',
  content_id: '',
  content_ja: '',
  content_ko: '',
  content_pt: '',
  content_th: '',
  content_vi: '',
  content_zh: '',
}

export const VersionTableColumns: ProColumns<VersionTableListItem>[] = [
  {
    title: "ID",
    width: 40,
    dataIndex: "id",
    align: 'center',
    fixed: "left",
  },
  {
    title: "状态",
    width: 100,
    dataIndex: "status",
    align: 'center',
    fixed: "left",
    initialValue: 'error',
    valueEnum: {
      online: {text: '发布成功', status: 'Success'},
      error: {text: '未发布', status: 'Error'},
    },
  },
  {
    title: "iOS",
    width: 100,
    dataIndex: "ios",
  },
  {
    title: "Android",
    width: 100,
    dataIndex: "android",
  },
  {
    title: "奖励",
    width: 200,
    dataIndex: "reward",
    ellipsis: true,
  },
  {
    title: "中文标题",
    width: 120,
    dataIndex: "name_cn",
    ellipsis: true,
  },
  {
    title: "德语标题",
    width: 120,
    dataIndex: "name_de",
    ellipsis: true,
  },
  {
    title: "法语标题",
    width: 120,
    dataIndex: "name_fr",
    ellipsis: true,
  },
  {
    title: "印尼标题",
    width: 120,
    dataIndex: "name_id",
    ellipsis: true,
  },
  {
    title: "日文标题",
    width: 120,
    dataIndex: "name_ja",
    ellipsis: true,
  },
  {
    title: "韩语标题",
    width: 120,
    dataIndex: "name_ko",
    ellipsis: true,
  },
  {
    title: "葡萄牙标题",
    width: 120,
    dataIndex: "name_pt",
    ellipsis: true,
  },
  {
    title: "泰语标题",
    width: 120,
    dataIndex: "name_th",
    ellipsis: true,
  },
  {
    title: "越南标题",
    width: 120,
    dataIndex: "name_vi",
    ellipsis: true,
  },
  {
    title: "繁中标题",
    width: 120,
    dataIndex: "name_zh",
    ellipsis: true,
  },
  {
    title: "中文内容",
    width: 120,
    dataIndex: "content_cn",
    ellipsis: true,
  },
  {
    title: "德语内容",
    width: 120,
    dataIndex: "content_de",
    ellipsis: true,
  },
  {
    title: "英语内容",
    width: 120,
    dataIndex: "content_en",
    ellipsis: true,
  },
  {
    title: "法语内容",
    width: 120,
    dataIndex: "content_fr",
    ellipsis: true,
  },
  {
    title: "印尼内容",
    width: 120,
    dataIndex: "content_id",
    ellipsis: true,
  },
  {
    title: "日本内容",
    width: 120,
    dataIndex: "content_ja",
    ellipsis: true,
  },
  {
    title: "韩国内容",
    width: 120,
    dataIndex: "content_ko",
    ellipsis: true,
  },
  {
    title: "葡萄牙内容",
    width: 120,
    dataIndex: "content_pt",
    ellipsis: true,
  },
  {
    title: "泰语内容",
    width: 120,
    dataIndex: "content_th",
    ellipsis: true,
  },
  {
    title: "越南内容",
    width: 120,
    dataIndex: "content_vi",
    ellipsis: true,
  },
  {
    title: "繁中内容",
    width: 120,
    dataIndex: "content_zh",
    ellipsis: true,
  },
];

export let VersionTableDataSource: VersionTableListItem[] = [];