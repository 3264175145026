import React, {Component} from "react";
import '../../css/style_app.css';
import DefaultTemplate from "../../Template/DefaultTemplate";
import {Button, Input, Space, Descriptions, Row, Col, Typography} from 'antd';
import PostServer from "../../Utils/http";
import {MethodCopyUserSave, MethodCouldCopy, MethodFindNetUserInfo, MethodFindUserSave} from "../../Utils/method";
import {MessageError, MessageSuccess} from "../../Template/MessageNet";
import TextArea from "antd/es/input/TextArea";

export default class UserCopy2 extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      FLevel: '',
      FName: '',
      TLevel: '',
      TName: '',
      ClassTxt: '',
      FUserID: '',
      TUserID: '',
    }
    this.onCopy = this.onCopy.bind(this);
    this.FindUser = this.FindUser.bind(this);
    this.onSearchFrom = this.onSearchFrom.bind(this);
    this.onSearchTo = this.onSearchTo.bind(this);
    this.onTxtAreaChange = this.onTxtAreaChange.bind(this);
  }

  async onSearchFrom(value: string, event: any) {
    if (value.length < 1) {
      MessageError('Error', 'input From value');
      return;
    }
    const data = await this.FindUser(value)
    this.setState({FLevel: data.level, FName: data.name});
  }

  async onSearchTo(value: string, event: any) {
    if (value.length < 1) {
      MessageError('Error', 'input To value');

      return;
    }
    const data = await this.FindUser(value);
    this.setState({TLevel: data.level, TName: data.name});
  }

  async FindUser(value: string) {
    this.setState({isOpen: true});
    const rsp = await PostServer(MethodFindNetUserInfo, JSON.stringify({userid: value, fname: ''}));
    this.setState({isOpen: false});
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    return rsp;
  }

  async onCopy() {
    if (this.state.TUserID.trim().length < 1 || this.state.FUserID.trim().length < 1) {
      MessageError('Error', 'From,To is Null');
      return;
    }
    this.setState({isOpen: true});
    const data = await PostServer(MethodCouldCopy, JSON.stringify({
      fromUserId: this.state.FUserID,
      toUserId: this.state.TUserID,
      classes: this.state.ClassTxt,
    }));
    this.setState({isOpen: false});
    if (data === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (data.err_code !== 0) {
      MessageError('Error', `Server to Code:${data.err_code}`);
      return;
    }
    MessageSuccess('Success', 'Copy Success');
  }

  onTxtAreaChange(e: any) {
    this.setState({ClassTxt: e.currentTarget.value});
  }

  indexPage = ['sub2', '302'];

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <>
          <Row>
            <Col span={5}/>
            <Col span={7}>
              <Input.Search
                id='fid'
                placeholder="Input From UserID"
                allowClear
                enterButton="From UserID"
                size="large"
                onSearch={this.onSearchFrom}
                onChange={(e) => {
                  this.setState({FUserID: e.target.value});
                }}
              />
            </Col>
            <Col span={7}>
              <Input.Search
                id='tid'
                placeholder="Input To UserID"
                allowClear
                enterButton="To UserID"
                size="large"
                onChange={(e) => {
                  this.setState({TUserID: e.target.value});
                }}
                onSearch={this.onSearchTo}
              />
            </Col>
            <Col span={5}/>
          </Row>
          <br/>
          <Row>
            <Col span={5}/>
            <Col span={7}>
              <Descriptions title="From User Info">
                <Descriptions.Item label="Level">{this.state.FLevel}</Descriptions.Item>
                <Descriptions.Item label="Name">{this.state.FName}</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={7}>
              <Descriptions title="To User Info">
                <Descriptions.Item label="Level">{this.state.TLevel}</Descriptions.Item>
                <Descriptions.Item label="Name">{this.state.TName}</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={5}/>
          </Row>
          <br/>
          <Row>
            <Col span={5}/>
            <Col span={14}>
              <Typography.Title level={5}>自定义Class(逗号分割):</Typography.Title>
              <TextArea id={'customClass'} onChange={this.onTxtAreaChange}/>
            </Col>
            <Col span={5}/>
          </Row>
          <br/>
          <Row>
            <Col span={5}/>
            <Col span={12}/>
            <Col span={2}>
              <Button type="primary" block onClick={this.onCopy}>
                Copy User
              </Button>
            </Col>
            <Col span={5}/>
          </Row>
        </>
      </DefaultTemplate>
    );
  }
}