import React, {Component} from "react"
import DefaultTemplate from "../../Template/DefaultTemplate";
import {Button, Divider, Input, Form, Tabs, Space, Select} from "antd";
import '../../css/style_app.css'
import PostServer from "../../Utils/http";
import {MethodGetVersion, MethodMarkVersion, MethodMarkWhiteList} from "../../Utils/method";
import {upperCase} from 'upper-case'
import {MessageError, MessageSuccess} from "../../Template/MessageNet";
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'


export default class MarkVersion extends Component<any, any> {

  constructor(props: any) {
    super(props);

    this.state = {
      isOpen: false,
      FromHtml: undefined,
      FormConfigHtml: undefined
    };
    this.onLoad = this.onLoad.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.onCallView = this.onCallView.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.onFromFinish = this.onFromFinish.bind(this);
  }

  async onFinish(values: any) {
    this.setState({isOpen: true});
    const req = await PostServer(MethodMarkVersion, JSON.stringify({version_obj: values}))
    this.setState({isOpen: false})
    if (req === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (req.err_code !== 0) {
      MessageError('Error', `Server to Code:${req.err_code}`);
      return;
    }
    MessageSuccess('Success', 'Update Success!');
  }

  async componentDidMount() {
    await this.onCallView();
  }

  onLoad(versionObj: any, whites: any) {
    const html = (
      <Form
        {...this.layout}
        layout="horizontal"
        onFinish={this.onFinish}
        style={{
          width: '70vh'
        }}
        initialValues={versionObj}
      >
        {
          Object.keys(versionObj).map((item, index) => {
            if (item === 'env') {
              return <Form.Item name={item} label={upperCase(item)}>
                <Input/>
              </Form.Item>
            } else {
              const itemObj = versionObj[item]
              return (
                <>
                  <Divider orientation={'center'}>{upperCase(item)}</Divider>
                  {Object.keys(itemObj).map((sItem, idx) => {
                    if (sItem === 'versions') {
                      return <Form.Item name={[item, sItem]} label={sItem}>
                        <Input.TextArea rows={3}/>
                      </Form.Item>
                    } else if (sItem === 'white_list') {
                      return <Form.Item name={[item, sItem]} label={sItem}>
                        <Select
                          mode={'multiple'}
                          size={'middle'}
                          options={whites[item]}
                          maxTagCount={5}
                        />
                      </Form.Item>
                    } else {
                      return <Form.Item name={[item, sItem]} label={sItem}>
                        <Input/>
                      </Form.Item>
                    }
                  })}
                </>
              )
            }
          })
        }
        <br/>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Mark Version
        </Button>
      </Form>
    );
    return html;
  }

  async onCallView() {
    this.setState({isOpen: true});
    const req = await PostServer(MethodGetVersion, '');
    this.setState({isOpen: false});
    if (req === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (req.err_code !== 0) {
      MessageError('Error', `Server to Code:${req.err_code}`);
      return;
    }
    const html = this.onLoad(req.version_obj, req.whites);
    this.setState({FromHtml: html});
  }

  async onFromFinish(values: any) {
    this.setState({isOpen: true});
    const req = await PostServer(MethodMarkWhiteList, JSON.stringify(values));
    this.setState({isOpen: false});
    if (req === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (req.err_code !== 0) {
      MessageError('Error', `Server to Code:${req.err_code}`);
      return;
    }
    MessageSuccess('Success','修改成功!')
  }

  onConfig(whites: any) {
    const html = (
      <Form
        onFinish={this.onFromFinish}
        initialValues={whites}
      >
        <Form.List name={'white_list'}>
          {(fields, {add, remove}) => (
            <Space direction={'vertical'}>
              {fields.map(({key, name, ...restField}) => (
                <Space direction={'horizontal'}>
                  <Form.Item name={[name, 'key']} label={'key'}>
                    <Input style={{width: '35vh'}}></Input>
                  </Form.Item>
                  <Form.Item name={[name, 'value']} label={'value'}>
                    <Input></Input>
                  </Form.Item>
                  <Form.Item>
                    <MinusCircleOutlined
                      onClick={() => remove(key)}
                    />
                  </Form.Item>
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                  Add field
                </Button>
              </Form.Item>
            </Space>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">Mark White</Button>
        </Form.Item>
      </Form>
    )
    this.setState({FormConfigHtml: html})
  }

  async onChangeTab(activeKey: string) {
    this.setState({isOpen: true});
    const req = await PostServer(MethodGetVersion, '');
    this.setState({isOpen: false});
    if (req === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (req.err_code !== 0) {
      MessageError('Error', `Server to Code:${req.err_code}`);
      return;
    }
    if (activeKey === '1') {
      const html = this.onLoad(req.version_obj, req.whites);
      this.setState({FromHtml: html});
    } else {
      const whites = [];
      for (let item of req.whites.apple) {
        const arr = item.value.split(':');
        whites.push({key: arr[0], value: arr[1]})
      }
      this.setState({FormConfigHtml:undefined});
      this.onConfig({white_list: whites});
    }
  }

  layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
  };

  itemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 20}
  };

  indexPage = ['sub100', '1004'];

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={false}>
        <Tabs centered onChange={this.onChangeTab}>
          <Tabs.TabPane tab={'Mark'} key={'1'}>
            <div className={'version-div-center'}>
              {this.state.FromHtml}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Config'} key={'2'}>
            <div className={'version-div-center'}>
              {this.state.FormConfigHtml}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </DefaultTemplate>
    );
  }
}