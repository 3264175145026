import React, {Component, ReactNode} from "react";

import DefaultTemplate from "../../Template/DefaultTemplate";
import {EditableProTable, ProColumns} from "@ant-design/pro-components";
import {HalloweenRankUserSource, HalloweenRankType} from "./HalloweenRankModel";
import {Button, DatePicker, Divider, InputNumber, Popconfirm, Select, Typography} from "antd";
import {Option} from "antd/es/mentions";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_CN";
import PostServer, {PostXlsx} from "../../Utils/http";
import {
  MethodHalloweenExportUser,
  MethodHalloweenRankDel,
  MethodHalloweenRankUser
} from "../../Utils/method";
import {MessageError} from "../../Template/MessageNet";


export default class HalloweenUserRank extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      RankType: 9,
      RankIdx: 1,
      RankNum: 100,
      RankDate: moment(new Date()).format('YYYY-MM-DD'),
    };
    this.onDel = this.onDel.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.GetSource = this.GetSource.bind(this);
    this.onExport = this.onExport.bind(this);
  }

  HalloweenUserRankColumns: ProColumns<HalloweenRankType>[] = [
    {
      title: 'User ID',
      dataIndex: 'user_id',
      readonly: true,
      width: '120px'
    },
    {
      title: 'User Name',
      dataIndex: 'user_name',
      readonly: true,
      width: '120px'
    },
    {
      title: 'User Rank',
      dataIndex: 'user_rank',
      readonly: true,
      width: '50px'
    },
    {
      title: 'User Score',
      dataIndex: 'user_score',
      width: '80px'
    },
    {
      title: 'User Level',
      dataIndex: 'user_level',
      width: '80px'
    },
    {
      title: 'Operation',
      valueType: 'option',
      width: 120,
      render: (text, record, idx, action) => [
        <Popconfirm
          title="删除,将删除所有榜的信息,并加入黑名单?"
          onConfirm={() => this.onDel(text, record, idx, action)}
          okText="Yes"
          cancelText="No"
        >
          <a href="#">DELETE</a>
        </Popconfirm>
      ]
    }
  ]

  async onDel(text: ReactNode, record: HalloweenRankType, index: number, action: any) {
    this.setState({isOpen: true});
    const req = {user_id: record.user_id, date_time: this.state.RankDate, idx: record.id};
    const rsp = await PostServer(MethodHalloweenRankDel, JSON.stringify(req));
    this.setState({isOpen: false});
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    HalloweenRankUserSource.splice(index, 1);
    this.formRef?.current.reload();
  }

  async onSelect() {
    this.setState({isOpen: true});
    const req = {
      rank_type: this.state.RankType,
      date_time: this.state.RankDate,
      num: this.state.RankNum,
      rank_idx: this.state.RankIdx,
    };
    const rsp = await PostServer(MethodHalloweenRankUser, JSON.stringify(req))
    this.setState({isOpen: false})
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    HalloweenRankUserSource.splice(0, HalloweenRankUserSource.length);
    if (rsp.ranks) {
      HalloweenRankUserSource.push(...rsp.ranks);
    }
    this.formRef?.current.reload();
  }

  async onExport() {
    this.setState({isOpen: true});
    const req = {
      rank_type: this.state.RankType,
      date_time: this.state.RankDate,
      num: this.state.RankNum,
      rank_idx: this.state.RankIdx,
    };
    const fileName = `Halloween_user_${req.date_time}.xlsx`;
    await PostXlsx(MethodHalloweenExportUser, JSON.stringify(req), fileName);
    this.setState({isOpen: false});
  }

  GetSource() {
    let tableSource: HalloweenRankType[] = JSON.parse(JSON.stringify(HalloweenRankUserSource));
    return tableSource;
  }

  componentWillUnmount() {
    HalloweenRankUserSource.splice(0, HalloweenRankUserSource.length);
  }

  formRef: any = {};
  indexPage: string[] = ['sub110', '1102'];

  UserIdxPool = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  RankTypePool = [{Idx: 0, Desc: 'Distance Pool'}, {Idx: 1, Desc: 'Kill Pool'}]

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <EditableProTable<HalloweenRankType>
          rowKey={'id'}
          scroll={{x: 960}}
          columns={this.HalloweenUserRankColumns}
          recordCreatorProps={false}
          actionRef={this.formRef}
          toolBarRender={() => [
            <Typography.Text style={{fontSize: 15}}>UserIDX Pool:</Typography.Text>,
            <Select defaultValue={this.UserIdxPool[1]} style={{width: 60}} onChange={(v) => {
              this.setState({RankIdx: Number(v)})
            }}>
              {this.UserIdxPool.map((item, idx) => {
                return (<Option value={`${item}`}>{item}</Option>)
              })}
            </Select>,
            <Typography.Text style={{fontSize: 15}}>Rank Pool:</Typography.Text>,
            <Select defaultValue={this.RankTypePool[0].Desc} style={{width: 160}} onChange={(v) => {
              this.setState({RankType: Number(v)})
            }}>
              {this.RankTypePool.map((item, idx) => {
                return (<Option value={`${item.Idx}`}>{item.Desc}</Option>)
              })}
            </Select>,
            <Divider type="vertical"/>,
            <InputNumber addonBefore="Count" style={{width: 150}} defaultValue={this.state.RankNum}
                         value={this.state.RankNum}
                         onChange={(v) => {
                           this.setState({RankNum: v});
                         }}/>,
            <Divider type="vertical"/>,
            <Typography.Text style={{fontSize: 15}}>Select DATE:</Typography.Text>,
            <DatePicker defaultValue={moment(new Date().getTime())}
                        format={'YYYY-MM-DD'} locale={locale} size={'large'}
                        onChange={(_, dateString) => {
                          this.setState({RankDate: dateString});
                        }}
            />,
            <Button type="primary" ghost onClick={this.onSelect}>
              SELECT
            </Button>,
            <Button type="primary" ghost onClick={this.onExport}>
              EXPORT
            </Button>
          ]}
          pagination={{
            pageSize: 20,
          }}
          request={async () => {
            const dbSource = this.GetSource();
            return {
              data: dbSource,
              total: dbSource.length,
              success: true,
            }
          }}
        >
        </EditableProTable>
      </DefaultTemplate>
    );
  }
}