import React, {Component} from "react";
import {Divider, Input, Typography} from 'antd'
import DefaultTemplate from "../../Template/DefaultTemplate";
import PostServer from "../../Utils/http";
import {MethodTransactionHistory} from "../../Utils/method";
import {MessageError} from "../../Template/MessageNet";

export default class FindHistoryOrders extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      DataHtml: undefined,
      isOpen: false,
    };
    this.onSearch = this.onSearch.bind(this);
  }

  async onSearch(value: string, event: any) {
    if (value.length < 10) {
      MessageError('Error', '输入了错误的查询信息!');
      return;
    }
    this.setState({isOpen: true})
    const rsp = await PostServer(MethodTransactionHistory, JSON.stringify({
      order: value.trim(),
    }));
    this.setState({isOpen: false});
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    if (!rsp.isOK) {
      this.setState({DataHtml: (<>{rsp.data}</>)})
      MessageError('Error', '查询错误,请重试!');
      return;
    }
    const json = JSON.parse(rsp.data);
    const html = (<>
      {json.map((item: any, index: number) => {
        const arr = item.state.split(',');
        if (arr.length===1){
          return (
            <>
              <Typography.Title level={2}>{item.state}</Typography.Title>
              <Typography.Text>{item.data}</Typography.Text>
            </>
          )
        }else {
          return (
            <>
              <Typography.Title level={2}>{arr[0]}</Typography.Title>
              <Typography.Title level={2}>{arr[1]}</Typography.Title>
              <Typography.Text>{item.data}</Typography.Text>
            </>
          )
        }

      })}
    </>);
    this.setState({DataHtml: html})
  }

  indexPage: string[] = ['sub3', '901']

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <Input.Search
          placeholder="Input Search Order id"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={this.onSearch}
        />
        <Divider plain>Data</Divider>
        {this.state.DataHtml}
      </DefaultTemplate>
    );
  }
}