import React, {useEffect, useState} from "react";
import "antd/dist/antd.css";
import "../css/style_app.css";
import {Layout, Menu, Spin} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {MenuList} from "./DefaultRoutes";
import {LoginData} from "../Local/local";
import {LoadPublicConfig} from "../Utils/util";


const {Header, Content, Footer, Sider} = Layout;

export default function DefaultTemplate(props: any) {
  const [collapsed, setCollapsed] = useState(false);
  const [subKeys, setSubKeys] = useState(props.subKeys);
  const navigate = useNavigate();
  const [name, setName] = useState("DEV");
  useEffect(() => {
    if (LoginData.Token.length < 1) {
      navigate('/');
      return
    }
    LoadPublicConfig((data: any) => {
      setName(data.Platform);
    });
  }, []);



  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="logo" />

        <Menu theme="dark" mode="inline" selectedKeys={props.page} openKeys={subKeys}
              onOpenChange={(openKeys => setSubKeys(openKeys))}>
          {
            MenuList.map((item, index) => {
              if (LoginData.MenuIdx.indexOf(String(item.menuIdx)) < 0) {
                return
              }
              if (item.key.indexOf('sub') >= 0) {
                const childs = item.items as Array<any>
                return (
                  <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
                    {childs.map((child, idx) => {
                      if (LoginData.MenuIdx.indexOf(String(child.menuIdx)) < 0) {
                        return
                      }
                      return (
                        <Menu.Item key={child.key}>
                          <Link to={child.path}>{child.label}</Link>
                        </Menu.Item>
                      )
                    })}
                  </Menu.SubMenu>
                )
              } else {
                return (
                  <Menu.Item key={item.key} icon={item.icon}>
                    <Link to={item.path}>{item.label}</Link>
                  </Menu.Item>)
              }
            })
          }
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
        >
          {name}
        </Header>
        <Content>
          <div style={{margin: '1vh 1vh'}}>
            <Spin tip="Loading..." spinning={props.isOpen} size={'large'}>
              {props.children}
            </Spin>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center"
          }}
        >
          Ant Design ©2022 Created by BIGFOOT
        </Footer>
      </Layout>
    </Layout>
  );
};

