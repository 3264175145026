export type RankType = {
  order: number,
  userId: string,
  userName: string,
  userScore: number,
  userLevel:number,
  userPay: number,
  userRegister: string,
}

export const RankSource: RankType[] = [];