import React, {Component, ReactNode} from "react";
import {EditableProTable, ProColumns} from "@ant-design/pro-components";
import {Divider, InputNumber, Button, Popconfirm} from 'antd';
import DefaultTemplate from "../../Template/DefaultTemplate";
import {ValentineRankSource, ValentineRankType} from "./ValentineModel";
import PostServer, {PostXlsx} from "../../Utils/http";
import 'moment/locale/zh-cn';
import {MessageError} from "../../Template/MessageNet";
import {MethodValentineRank, MethodValentineRankDel, MethodValentineRankExport} from "../../Utils/method";


export default class ValentineRank extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      visible: false,
      UserId: '',
      Call: this.onCall,
      RankNum: 100,
    };
    this.onDel = this.onDel.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.GetSource = this.GetSource.bind(this);
    this.onCall = this.onCall.bind(this);
    this.onExport = this.onExport.bind(this);
  }

  RankColumns: ProColumns<ValentineRankType>[] = [
    {
      title: 'Rank IDX',
      dataIndex: 'order',
      readonly: true,
      width: '120px'
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      readonly: true,
      width: '120px'
    }, {
      title: 'User Name',
      dataIndex: 'userName',
      readonly: true,
      width: '120px'
    }, {
      title: 'User Score',
      dataIndex: 'userScore',
      width: '80px'
    }, {
      title: 'User Level',
      dataIndex: 'userLevel',
      width: '80px',

    }, {
      title: 'Operation',
      valueType: 'option',
      width: 120,
      render: (text, record, idx, action) => [
        <Popconfirm
          title="删除,将删除榜内的信息?"
          onConfirm={() => this.onDel(text, record, idx, action)}
          okText="Yes"
          cancelText="No"
        >
          <a href="#">DELETE</a>
        </Popconfirm>
      ]
    }
  ];


  onCall() {
    this.setState({visible: false, UserId: ''});
  }

  async onDel(text: ReactNode, record: ValentineRankType, index: number, _: any) {
    const req = {userId: record.userId};
    console.log(record);
    const rsp = await PostServer(MethodValentineRankDel, JSON.stringify(req));
    this.setState({isOpen: false});
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    ValentineRankSource.splice(index, 1);
    this.formRef?.current.reload();
  }

  async onSelect() {
    this.setState({isOpen: true});
    const req = {
      count: this.state.RankNum
    };
    const rsp = await PostServer(MethodValentineRank, JSON.stringify(req))
    this.setState({isOpen: false})
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    console.log(rsp.ranks);
    ValentineRankSource.splice(0, ValentineRankSource.length);
    if (rsp.ranks) {
      ValentineRankSource.push(...rsp.ranks);
    }
    this.formRef?.current.reload();
  }

  async onExport() {
    this.setState({isOpen: true});
    const req = {num: this.state.RankNum};
    const fileName = 'valentine.xlsx';
    await PostXlsx(MethodValentineRankExport, JSON.stringify(req), fileName);
    this.setState({isOpen: false});
  }

  GetSource() {
    let tableSource: ValentineRankType[] = JSON.parse(JSON.stringify(ValentineRankSource));
    return tableSource;
  }

  componentWillUnmount() {
    ValentineRankSource.splice(0, ValentineRankSource.length);
  }

  formRef: any = {};
  indexPage: string[] = ['sub160', '1601'];

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <EditableProTable<ValentineRankType>
          rowKey={'order'}
          scroll={{x: 960}}
          columns={this.RankColumns}
          recordCreatorProps={false}
          actionRef={this.formRef}
          toolBarRender={() => [
            <Divider type="vertical"/>,
            <InputNumber addonBefore="Count" style={{width: 150}} defaultValue={this.state.RankNum}
                         value={this.state.RankNum}
                         onChange={(v) => {
                           this.setState({RankNum: v});
                         }}/>,
            <Divider type="vertical"/>,
            <Button type="primary" ghost onClick={this.onSelect}>
              SELECT
            </Button>,
            <Button type="primary" ghost onClick={this.onExport}>
              EXPORT
            </Button>
          ]}
          pagination={{
            pageSize: 20,
          }}
          request={async () => {
            const dbSource = this.GetSource();
            return {
              data: dbSource,
              total: dbSource.length,
              success: true,
            }
          }}
        >
        </EditableProTable>

      </DefaultTemplate>
    );
  }
}