import React, {Component} from "react";

import DefaultTemplate from "../../Template/DefaultTemplate";
import {Button, DatePicker, Form} from "antd";
import {ProFormTextArea} from "@ant-design/pro-components";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_CN";
import PostServer from "../../Utils/http";
import {MethodHalloweenRankUserDel} from "../../Utils/method";


export default class RankUserDel extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      RankDate: moment(new Date()).format('YYYY-MM-DD'),
    };
    this.onFinish = this.onFinish.bind(this);
  }

  async onFinish(values: any) {
    let dateStr = moment(new Date()).format('YYYY-MM-DD');
    if (values.date_time) {
      dateStr = values.date_time.format('YYYY-MM-DD');
    }
    const rsp = await PostServer(MethodHalloweenRankUserDel, JSON.stringify({
      date_time: dateStr,
      user_id: values.user_ids
    }))
    this.setState({isOpen: false})
    if (rsp === undefined) {
      console.log('请求失败!')
      return;
    }
    if (rsp.err_code !== 0) {
      console.log(`Server to Code:${rsp.err_code}`)
      return;
    }
  }

  indexPage: string[] = ['sub110', '1103'];

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <div className={"login-div-center"}>
          <Form
            layout="vertical"
            onFinish={this.onFinish}
            style={{
              width: '600px'
            }}
          >
            <Form.Item name={'dt_time'} label={'Select DATE:'}>
              <DatePicker style={{width: '100%'}} defaultValue={moment(new Date().getTime())}
                          format={'YYYY-MM-DD'} locale={locale} size={'large'} onChange={
                (_, dateString) => {
                  this.setState({RankDate: dateString});
                }}/>
            </Form.Item>

            <Form.Item
              name="user_ids"
              label={'UserID:'}
              rules={[
                {
                  required: true,
                  message: 'Please input your UserName!',
                },
                {
                  type: 'string',
                  min: 6,
                }
              ]}
            >
              <ProFormTextArea placeholder='input userid,use "," split'/>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                DELETE USERS
              </Button>
            </Form.Item>
          </Form>
        </div>
      </DefaultTemplate>
    );
  }
}