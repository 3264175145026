import React, {Component, ReactNode} from "react";
import {EditableProTable, ProColumns} from "@ant-design/pro-components";
import {Button, DatePicker, Divider, Input, InputNumber, Popconfirm, Typography} from 'antd';
import DefaultTemplate from "../../Template/DefaultTemplate";
import {RankSource, RankType} from "./RankModel";
import PostServer, {PostXlsx} from "../../Utils/http";
import {MethodRankDel, MethodRankExport, MethodRankList,} from "../../Utils/method";
import moment from "moment";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import {MessageError} from "../../Template/MessageNet";


export default class RankView extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      visible: false,
      UserId: '',
      Call: this.onCall,
      RankNum: 100,
      RankDate: moment(new Date()).format('YYYY-MM-DD'),
      RankName: '',

    };
    this.onDel = this.onDel.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.GetSource = this.GetSource.bind(this);
    this.onCall = this.onCall.bind(this);
    this.onExport = this.onExport.bind(this);
  }

  RankColumns: ProColumns<RankType>[] = [
    {
      title: 'Rank IDX',
      dataIndex: 'order',
      readonly: true,
      width: '50px'
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      readonly: true,
      width: '120px'
    }, {
      title: 'User Name',
      dataIndex: 'userName',
      readonly: true,
      width: '100px'
    }, {
      title: 'User Score',
      dataIndex: 'userScore',
      width: '50px'
    }, {
      title: 'User Level',
      dataIndex: 'userLevel',
      width: '50px',

    }, {
      title: 'User Register',
      dataIndex: 'userRegister',
      width: '100px',

    }, {
      title: 'User Payment',
      dataIndex: 'userPay',
      width: '50px',
    }, {
      title: 'Operation',
      valueType: 'option',
      width: 120,
      render: (text, record, idx, action) => [
        <Popconfirm
          title="删除,将删除所有榜的信息?"
          onConfirm={() => this.onDel(text, record, idx, action)}
          okText="Yes"
          cancelText="No"
        >
          <a href="#">DELETE</a>
        </Popconfirm>
      ]
    }
  ];


  onCall() {
    this.setState({visible: false, UserId: ''});
  }

  async onDel(text: ReactNode, record: RankType, index: number, _: any) {
    const req = {
      date: this.state.RankDate,
      actName: this.state.RankName,
      userId: record.userId
    };
    console.log(req)
    const rsp = await PostServer(MethodRankDel, JSON.stringify(req));
    this.setState({isOpen: false});
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    RankSource.splice(index, 1);
    this.formRef?.current.reload();
  }

  async onSelect() {
    this.setState({isOpen: true});
    const req = {
      date: this.state.RankDate,
      actName: this.state.RankName,
      count: this.state.RankNum,
    };
    console.log(req)
    const rsp = await PostServer(MethodRankList, JSON.stringify(req))
    this.setState({isOpen: false})
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    RankSource.splice(0, RankSource.length);
    if (rsp.ranks) {
      RankSource.push(...rsp.ranks);
    }
    this.formRef?.current.reload();
  }

  async onExport() {
    this.setState({isOpen: true});
    const req = {
      date: this.state.RankDate,
      actName: this.state.RankName,
      count: this.state.RankNum,
    };
    const fileName = `rank_${this.state.RankDate}.xlsx`;
    await PostXlsx(MethodRankExport, JSON.stringify(req), fileName);
    this.setState({isOpen: false});
  }

  GetSource() {
    let tableSource: RankType[] = JSON.parse(JSON.stringify(RankSource));
    return tableSource;
  }

  componentWillUnmount() {
    RankSource.splice(0, RankSource.length);
  }

  formRef: any = {};
  indexPage: string[] = ['sub170', '1701'];

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <EditableProTable<RankType>
          rowKey={'order'}
          scroll={{x: 960}}
          columns={this.RankColumns}
          recordCreatorProps={false}
          actionRef={this.formRef}
          toolBarRender={() => [
            <Typography.Text style={{fontSize: 15}}>ActivityName:</Typography.Text>,
            <Input placeholder='输入活动名!'

                   onChange={(v) => {
                     this.setState({RankName: v.currentTarget.value})
                   }}/>,
            <Divider type="vertical"/>,
            <InputNumber addonBefore="Count" style={{width: 150}} defaultValue={this.state.RankNum}
                         value={this.state.RankNum}
                         onChange={(v) => {
                           this.setState({RankNum: v});
                         }}/>,
            <Divider type="vertical"/>,
            <Typography.Text style={{fontSize: 15}}>OPEN DATE:</Typography.Text>,
            <DatePicker defaultValue={moment(new Date())}
                        showTime={{defaultValue: moment('2000-05-05 05', 'YYYY-MM-DD')}}
                        format={'YYYY-MM-DD'} locale={locale} size={'large'}
                        onChange={(_, dateString) => {
                          this.setState({RankDate: dateString});
                        }}
            />,
            <Button type="primary" ghost onClick={this.onSelect}>
              SELECT
            </Button>,
            <Button type="primary" ghost onClick={this.onExport}>
              EXPORT
            </Button>
          ]}
          pagination={{
            pageSize: 20,
          }}
          request={async () => {
            const dbSource = this.GetSource();
            return {
              data: dbSource,
              total: dbSource.length,
              success: true,
            }
          }}
        >
        </EditableProTable>

      </DefaultTemplate>
    );
  }
}