import React, {Component} from 'react';
import {
  ProForm,
  ProFormText,
  ProFormTextArea
} from '@ant-design/pro-components';
import {Modal, Divider} from "antd";
import {UserMailLanguageData, UserJsonKey, UserMailTableDataSource} from "./Model";
import '../../css/style_modal_scroll.css'
import PostServer from "../../Utils/http";
import {MethodAddLocalUserMail} from "../../Utils/method";
import {MessageError} from "../../Template/MessageNet";

export default class AddUserMailForm extends Component<any> {

  state = {
    visible: false,
    json: UserJsonKey,
    call: null,
  };

  constructor(props: any) {
    super(props);
    this.OnClose = this.OnClose.bind(this);
  }

  HandleCall = (e: any) => {
    this.OnClose()
  };

  OnClose() {
    this.setState({
      visible: false,
      json: UserJsonKey,
    });
  }

  //判断传过来的visible 改变模态框状态是否隐藏
  componentWillReceiveProps(nextProps: any) {
    if (nextProps.visible !== this.state.visible) {
      const localJson: any = JSON.parse(JSON.stringify(UserJsonKey))
      const reqJson = Object.keys(nextProps?.json);
      const locJson = Object.keys(localJson);
      reqJson?.forEach((j1) => {
        const jRep = j1.replace('-', '_');
        locJson.forEach((j2) => {
          if (j2.toString() === jRep) {
            localJson[j2] = nextProps.json[j1];
          }
        });
      })
      this.setState({
        visible: nextProps.visible,
        json: localJson,
        call: nextProps.call,
      })
    }
  }

  // onSubmit 添加,创建一条数据
  async OnCreate(value: any) {
    value.status = 'error';
    if (value.reward.length < 1) {
      value.reward = '[]';
    } else {
      try {
        const rewards = JSON.parse(value.reward);
        if (rewards[0] === undefined || rewards[0].id === undefined) {
          value.reward = '[]';
        }
      } catch (e) {
        value.reward = '[]';
      }
    }
    value.id = Number(value.id);
    const data = await PostServer(MethodAddLocalUserMail, JSON.stringify({
      user_mail: value,
    }));
    if (data === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (data.err_code !== 0) {
      MessageError('Error', `Server to Code:${data.err_code}`);
      return;
    }
    value.key = data.key;
    UserMailTableDataSource.push(value);
    const callback: any = this.state.call;
    callback(value);
    this.OnClose();
  }

  FormRules: any = [{
    required: true,
    message: 'Please input your data!',
  }, {
    type: 'string',
    min: 1,
    message: 'Please input your string right!',
  }];

  render() {
    return (
      <>
        <Modal
          title={'Add Mail'} //标题
          visible={this.state.visible} //visible 判断是否显示模态框 (true | false)
          onCancel={this.HandleCall}
          footer={false}
          width={800}
          maskClosable={false}
          destroyOnClose={true}
        >
          <div className="scorll_form">
            <ProForm
              title="New Mail"
              onFinish={async (values) => {
                await this.OnCreate(values);
                return true;
              }}
              onReset={() => {
                this.setState({
                  json: JSON.parse(JSON.stringify(UserJsonKey)),
                });
              }}
              request={async (params) => {
                return {
                  id: this.state.json.id,
                  userid: this.state.json.userid,
                  reward: this.state.json.reward,
                  des: this.state.json.des,
                  name_cn: this.state.json.name_cn,
                  name_de: this.state.json.name_de,
                  name_en: this.state.json.name_en,
                  name_fr: this.state.json.name_fr,
                  name_id: this.state.json.name_id,
                  name_ja: this.state.json.name_ja,
                  name_ko: this.state.json.name_ko,
                  name_pt: this.state.json.name_pt,
                  name_th: this.state.json.name_th,
                  name_vi: this.state.json.name_vi,
                  name_zh: this.state.json.name_zh,
                  content_cn: this.state.json.content_cn,
                  content_de: this.state.json.content_de,
                  content_en: this.state.json.content_en,
                  content_fr: this.state.json.content_fr,
                  content_id: this.state.json.content_id,
                  content_ja: this.state.json.content_ja,
                  content_ko: this.state.json.content_ko,
                  content_pt: this.state.json.content_pt,
                  content_th: this.state.json.content_th,
                  content_vi: this.state.json.content_vi,
                  content_zh: this.state.json.content_zh,
                };
              }}
            >
              <ProFormText width="md" name="id" label="邮件ID" tooltip="必填" placeholder="请输入邮件ID"/>
              <ProFormTextArea  name="userid" label="玩家ID" tooltip="必填" placeholder="请输入玩家ID" colProps={{span:24}}
                           rules={this.FormRules}/>
              <ProFormTextArea name="reward" label='奖励-格式:[{"id": 2,"num": 100,"type": 1}]'
                               placeholder='奖励Reward:[{"id": 2,"num": 100,"type": 1}]' />
              <ProFormTextArea colProps={{span: 24}} name="des" label="备注"/>
              <Divider plain>多语言配置</Divider>
              {UserMailLanguageData.map((model, index) => (
                <div key={index}>
                  <ProFormText width="md" name={model.key_title} label={model.title}
                               placeholder={`输入${model.title}`}/>
                  <ProFormTextArea name={model.key_content} colProps={{span: 24}}
                                   label={model.content}
                                   placeholder={`输入${model.content}`}/>
                </div>
              ))}
            </ProForm>
          </div>
        </Modal>
      </>
    );
  }
}