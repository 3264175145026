import React, {Component} from 'react';

import {Modal} from "antd";
import '../../css/style_modal_scroll.css'
import { MethodHalloweenRankPlayerInfo} from "../../Utils/method";
import PostServer from "../../Utils/http";


export default class RankPlayerInfo extends Component<any> {
  state = {
    visible: false,
    DateTime: '',
    UserId: '',
    OnCall: null,
  };

  constructor(props: any) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  handleCancel = (e: any) => {
    this.onClose()
  };

  onClose() {
    const Callback: any = this.state.OnCall;
    Callback();
  }

  //判断传过来的visible 改变模态框状态是否隐藏
  componentWillReceiveProps(nextProps: any) {
    if (nextProps.visible !== this.state.visible) {
      this.setState({
        visible: nextProps.visible,
        DateTime: nextProps.dateTime,
        UserId: nextProps.userId,
        OnCall: nextProps.onCall,
      })
    }
    if (nextProps.visible){
      console.log(2342)
    }
  }

  async componentDidMount() {
    if (this.state.DateTime.length < 1 || this.state.UserId.length < 1 || !this.state.visible) {
      return;
    }
    const req = {datetime: this.state.DateTime, user_id: this.state.UserId}
    const rsp = await PostServer(MethodHalloweenRankPlayerInfo, JSON.stringify(req))
    this.setState({isOpen: false})
    if (rsp === undefined) {
      console.log('请求失败!')
      return;
    }
    if (rsp.err_code !== 0) {
      console.log(`Server to Code:${rsp.err_code}`)
      return;
    }
  }


  render() {
    return (
      <>
        <Modal
          title={'Rank Player Info'} //标题
          visible={this.state.visible} //visible 判断是否显示模态框 (true | false)
          onCancel={this.handleCancel}
          footer={false}
          width={800}
          key={'4'}
          destroyOnClose={true}
          maskClosable={false}
          className={"overflow-y：auto"}
        >
        </Modal>
      </>
    );
  }
}