import {ProColumns} from "@ant-design/pro-components";

export type UserCmdTableItem = {
  key: number;
  id: number;
  status: string;
  userid:string;
  cmd:string;
  des:string;
};

export const UserCmdJsonKey = {
  id: 1,
  status: 'error',
  userid: '',
  cmd: '',
  des: '',
}

export const UserCmdTableColumns: ProColumns<UserCmdTableItem>[] = [
  {
    title: "ID",
    width: 60,
    dataIndex: "id",
    align: 'center',
    fixed: "left",
  },
  {
    title: "状态",
    width: 120,
    dataIndex: "status",
    align: 'center',
    fixed: "left",
    initialValue: 'error',
    valueEnum: {
      online: {text: '发布成功', status: 'Success'},
      error: {text: '未发布', status: 'Error'},
    },
  },
  {
    title: '玩家ID',
    width: 230,
    dataIndex: 'userid',
    fixed: "left",
    ellipsis: true,
  },
  {
    title: 'GM指令',
    dataIndex: 'cmd',
    width: 280,
  },
  {
    title: '备注',
    dataIndex: 'des',
  },
]

export let UserCmdTableDataSource: UserCmdTableItem[] = []