import React, {Component} from "react";
import '../../css/style_app.css';
import {Badge, Button, Col, Descriptions, Divider, Input, Popconfirm, Row, Space, Table, Typography} from "antd";
import PostServer from "../../Utils/http";
import {
  MethodBannedUser,
  MethodDelAllUserMail2,
  MethodDelUserMail2,
  MethodFindUserInfo,
  MethodMarkUserSaveId,
  MethodTransUserSave
} from "../../Utils/method";
import DefaultTemplate from "../../Template/DefaultTemplate";
import {MessageError, MessageInfo, MessageSuccess} from "../../Template/MessageNet";
import {ColumnsType} from "antd/es/table";
import DescriptionsItem from "antd/es/descriptions/Item";
import {DeleteOutlined} from "@ant-design/icons";

type TableType = {
  mailId: number,
  reward: string,
  cn_name: string,
  cn_content: string,
}

export default class UserInfo extends Component<any, any> {

  MailTableColumns: ColumnsType<TableType> = [
    {
      title: 'MailID',
      dataIndex: 'mailId',
      width: 100,
    }, {
      title: 'Reward',
      dataIndex: 'reward',
      ellipsis: true,
    }, {
      title: 'CN_Name',
      dataIndex: 'cn_name',
      width: 200,
      ellipsis: true,
    }, {
      title: 'CN_Content',
      dataIndex: 'cn_content',
      width: 250,
      ellipsis: true,
    }, {
      title: 'operation',
      dataIndex: 'operation',
      width: 100,
      render: (_, record) =>
        <Popconfirm title="Sure to delete?"
                    onConfirm={() => this.onDelete(record.mailId)}>
          <a>DELETE</a>
        </Popconfirm>
    }
  ];
  indexPage: string[] = ['sub2', '301'];

  constructor(props: any) {
    super(props);
    this.state = {
      DataHtml: undefined,
      isOpen: false,
      UserID: '',
      MarkHtml: undefined,
      TableSource: [],
    };
    this.onSearch = this.onSearch.bind(this);
    this.onTransfer = this.onTransfer.bind(this);
    this.SetState = this.SetState.bind(this);
    this.onBanUser = this.onBanUser.bind(this);
    this.onMarkSaveId = this.onMarkSaveId.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onLoadTable = this.onLoadTable.bind(this);
    this.onDelAll = this.onDelAll.bind(this);
  }

  async onDelete(mailId: number) {
    this.SetState(true);
    const rsp = await PostServer(MethodDelUserMail2, JSON.stringify({userId: this.state.UserID, mailId: mailId}));
    this.SetState(false);
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    const table = this.state.TableSource;
    const tempTable = [];
    for (let item of table) {
      if (item.mailId !== mailId) {
        tempTable.push(item);
      }
    }
    this.setState({TableSource: tempTable});
    MessageSuccess('Success', 'DELETE!');
  }

  async onDelAll() {
    this.SetState(true);
    const rsp = await PostServer(MethodDelAllUserMail2, JSON.stringify({userId: this.state.UserID}));
    this.SetState(false);
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    this.setState({TableSource: []});
  }

  async onBanUser(isBanned: boolean) {
    this.SetState(true);
    const rsp = await PostServer(MethodBannedUser, JSON.stringify({userid: this.state.UserID, isBanned: isBanned}));

    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    const rsp1 = await PostServer(MethodFindUserInfo, JSON.stringify({userid: this.state.UserID}))
    this.SetState(false);
    if (rsp1 === undefined) {
      MessageError('Error', '请求失败1!');
      return;
    }
    if (rsp1.err_code !== 0) {
      MessageError('Error', `Server1 to Code:${rsp1.err_code}`);
      return;
    }
    this.onLoad(rsp1);
    MessageSuccess('Success', '');
  }

  async onTransfer(toUserID: string, event: any) {
    this.SetState(true)
    if (!toUserID) {
      toUserID = this.state.UserID;
    }
    const rsp = await PostServer(
      MethodTransUserSave,
      JSON.stringify({user_id: toUserID, from_user_id: this.state.UserID}),
    );
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      this.SetState(false);
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      this.SetState(false);
      return;
    }
    this.setState({UserID: toUserID});
    await this.onSearch(toUserID, event);
  }

  SetState(isOk: boolean) {
    this.setState({isOpen: isOk});
  }

  async onMarkSaveId(value: string) {
    console.log(value)
    this.SetState(true);
    const rsp = await PostServer(MethodMarkUserSaveId, JSON.stringify({
      userid: this.state.UserID,
      save_id: Number(value.trim())
    }))
    this.SetState(false);
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    MessageSuccess('Success', '');
  }

  async onSearch(value: string, event: any) {
    if (value.trim().length < 1) {
      MessageInfo('Info', '未输入查询数据');
      return;
    }
    this.SetState(true);
    this.setState({UserID: value.trim()});
    const rsp = await PostServer(MethodFindUserInfo, JSON.stringify({userid: value.trim()}))
    this.SetState(false);
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    this.setState({TableSource: rsp.info.UserMail2});
    this.onLoad(rsp);
    this.onLoadTable(rsp);
  }

  onLoad(rsp: any) {
    let infoArray = [];
    for (let key in rsp.info) {
      if (key !== 'UserMail2') {
        infoArray.push({name: key, data: rsp.info[key]});
      }
    }
    /*
    const html = (
      <Space direction={'horizontal'}>
        {
          infoArray.map((item, idx) => {
            return (
              <Space direction={'vertical'}>
                <Typography.Title level={4}>{item.name}</Typography.Title>
                {
                  Object.keys(item.data).map((it, index) => {
                    if (it === 'saveId') {
                      return <Space direction={'horizontal'}>
                        <Typography.Text>{it}:</Typography.Text>
                        <Input.Search placeholder="Input User ID"
                                      allowClear
                                      enterButton="Mark SaveId"
                                      defaultValue={item.data[it]}
                                      onSearch={this.onMarkSaveId}/>
                      </Space>
                    } else if (it === 'authData') {
                      return <Space direction={"horizontal"}>
                        <Typography.Text>{it}:</Typography.Text>
                        <Space direction={'vertical'}>
                          {
                            Object.values(item.data[it]).map((it1, index1) => {
                              return <Typography.Text>{`${it1}`}</Typography.Text>
                            })
                          }
                        </Space>
                      </Space>
                    } else if (it === 'isBanned') {
                      return <Space direction={'horizontal'}>
                        <Typography.Text>{it}:</Typography.Text>
                        <Typography.Text>{`${item.data[it]}`}</Typography.Text>
                        <Button type="primary" danger onClick={this.onBanUser}>封号</Button>
                      </Space>
                    } else {
                      return <Space direction={'horizontal'}>
                        <Typography.Text>{it}:</Typography.Text>
                        <Typography.Text>{`${item.data[it]}`}</Typography.Text>
                      </Space>
                    }

                  })
                }
              </Space>
            )
          })
        }
      </Space>
    )
    */
    const html = (
      infoArray.map((item, index) => {
        return <Descriptions title={item.name} column={1} bordered style={{backgroundColor: '#FFFFFF', width: '60vh'}}>
          {
            Object.keys(item.data).map((it, idx) => {
              if (it === 'isBanned') {
                if (item.data[it]) {
                  return <DescriptionsItem label={it}>
                    <Space direction={'horizontal'}>
                      <Typography.Text>{`${item.data[it]}`}</Typography.Text>
                      <Button type="primary" danger onClick={() => this.onBanUser(false)}>解封</Button>
                    </Space>
                  </DescriptionsItem>
                } else {
                  return <DescriptionsItem label={it}>
                    <Space direction={'horizontal'}>
                      <Typography.Text>{`${item.data[it]}`}</Typography.Text>
                      <Button type="primary" danger onClick={() => this.onBanUser(true)}>封号</Button>
                    </Space>
                  </DescriptionsItem>
                }
              } else if (it === 'isNetwork') {
                if (item.data[it]) {
                  return <DescriptionsItem label={it}>
                    <Badge status={'success'} text={`${item.data[it]}`}/>
                  </DescriptionsItem>
                } else {
                  return <DescriptionsItem label={it}>
                    <Badge status={'error'} text={`${item.data[it]}`}/>
                  </DescriptionsItem>
                }
              } else if (it === 'authData') {
                return <DescriptionsItem label={it}>
                  {
                    Object.values(item.data[it]).map((it1, index1) => {
                      return <>{`${it1}`}<br/></>
                    })
                  }
                </DescriptionsItem>
              } else if (it === 'transfer') {
                return <DescriptionsItem label={it}>
                  <Input.Search
                    placeholder="Transfer to UserID, default self"
                    allowClear
                    enterButton="转换存档"
                    onSearch={this.onTransfer}
                  />
                </DescriptionsItem>
              } else {
                return <DescriptionsItem label={it}>{`${item.data[it]}`}</DescriptionsItem>
              }
            })
          }
        </Descriptions>
      })
    )
    this.setState({DataHtml: html});
  }

  onLoadTable(rsp: any) {
    const html = (
      <Table
        rowKey={'mailId'}
        columns={this.MailTableColumns}
        dataSource={this.state.TableSource}
        style={{maxWidth: '120vh', backgroundColor: '#FFFFFF'}}
        pagination={{pageSize: 10}}
        title={() => <Row>
          <Col span={8}>
            <Typography.Title level={5}>User Mail 2</Typography.Title>
          </Col>
          <Col span={4} offset={12}>
            <Button icon={<DeleteOutlined/>} onClick={this.onDelAll}>DELETE ALL MAIL</Button>
          </Col>
        </Row>
        }

      />
    )
    this.setState({TableHtml: html})
  }

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <div className={'div-center-userinfo'}>
          <Input.Search
            placeholder="Input User ID"
            allowClear
            enterButton="Search"
            size="large"
            style={{width: '50vh'}}
            onSearch={this.onSearch}
          />
        </div>
        <Divider plain>User Info</Divider>
        <div className={'div-center-userinfo'}>
          {this.state.DataHtml}
        </div>
        <br/>
        <div className={'div-center-userinfo'}>
          {this.state.TableHtml}
        </div>
      </DefaultTemplate>
    );
  }
}