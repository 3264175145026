import React, {Component} from 'react';
import {
  ProForm,
  ProFormText,
  ProFormTextArea
} from '@ant-design/pro-components';
import {Modal} from "antd";
import {UserCmdJsonKey, UserCmdTableDataSource} from "./Model";
import '../../css/style_modal_scroll.css'
import PostServer from "../../Utils/http";
import {MessageError} from "../../Template/MessageNet";
import {MethodAddLocalUserCmd} from "../../Utils/method";

export default class AddUserCmdForm extends Component<any> {
  state = {
    visible: false,
    json: UserCmdJsonKey,
    call: null,
  };

  constructor(props: any) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  handleCancel = (e: any) => {
    this.onClose()
  };

  onClose() {
    this.setState({
      visible: false,
      json: UserCmdJsonKey,
    });
  }

  //判断传过来的visible 改变模态框状态是否隐藏
  componentWillReceiveProps(nextProps: any) {
    if (nextProps.visible !== this.state.visible) {
      const localJson: any = JSON.parse(JSON.stringify(UserCmdJsonKey));
      const reqJson = Object.keys(nextProps.json);
      const locJson = Object.keys(localJson);
      reqJson.forEach((j1) => {
        const jRep = j1.replace('-', '_');
        locJson.forEach((j2) => {
          if (j2.toString() === jRep) {
            localJson[j2] = nextProps.json[j1];
          }
        });
      })
      this.setState({
        visible: nextProps.visible,
        json: localJson,
        call: nextProps.call,
      })
    }
  }

  // onSubmit 添加,创建一条数据
  async onSubmit(value: any) {
    value.status = 'error';
    const array = value.cmd.split(",");
    if (array.length < 2) {
      MessageError('Error', '错误的GM指令!');
      return;
    }
    const data = await PostServer(MethodAddLocalUserCmd, JSON.stringify({
      userid: value.userid,
      id: Number(value.id),
      args: array,
      des: value.des,
    }));
    if (data === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (data.err_code !== 0) {
      MessageError('Error', `Server to Code:${data.err_code}`);
      return;
    }
    const keyMap = new Map(Object.entries(data.keys));
    keyMap.forEach((val: any, key: any) => {
      let v = JSON.parse(JSON.stringify(value));
      v.key = Number(key);
      v.userid = val;
      UserCmdTableDataSource.push(v);
    })
    const callback: any = this.state.call;
    callback(value);
    this.onClose();
  }

  FormRules: any = [{
    required: true,
    message: 'Please input your data!',
  }, {
    type: 'string',
    min: 1,
    message: 'Please input your string right!',
  }];

  render() {
    return (
      <>
        <Modal
          title={'Add Cmd'} //标题
          visible={this.state.visible} //visible 判断是否显示模态框 (true | false)
          maskClosable={false}
          onCancel={this.handleCancel}
          footer={false}
          width={800}
          destroyOnClose={true}
          className={"overflow-y：auto"}
        >
          <ProForm
            title="New Mail"
            onFinish={async (values) => {
              this.onSubmit(values);
              return true;
            }}
            onReset={() => {
              const localJson: any = JSON.parse(JSON.stringify(UserCmdJsonKey))
              this.setState({
                json: localJson,
              })
              console.log('reSet')
            }}
            request={async (params) => {
              return {
                id: this.state.json.id,
                userid: this.state.json.userid,
                cmd: this.state.json.cmd,
                des: this.state.json.des,
              };
            }}
          >
            <ProFormText width="md" name="id" label="指令ID" tooltip="必填" placeholder="请输入指令ID"/>
            <ProFormTextArea name="userid" label='用户格式:123,123'
                             placeholder='用户格式:123,123' rules={this.FormRules}/>
            <ProFormTextArea name="cmd" label='GM指令:order,5201|6'
                             placeholder='GM指令格式:order,5201|6' rules={this.FormRules}/>
            <ProFormTextArea colProps={{span: 24}} name="des" label="备注"/>
          </ProForm>
        </Modal>
      </>
    );
  }
}