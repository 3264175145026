import React, {Component} from "react";
import '../../css/style_app.css';
import DefaultTemplate from "../../Template/DefaultTemplate";
import {Button, Input, Space, Typography, Select, Descriptions, Row, Col} from 'antd';
import PostServer from "../../Utils/http";
import {
  MethodCouldCopyApps, MethodFindNetUserInfo,
  MethodGetCrossUserSave
} from "../../Utils/method";
import {MessageError, MessageInfo, MessageSuccess} from "../../Template/MessageNet";
import TextArea from "antd/es/input/TextArea";

export default class CrossUserCopy2 extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      FLevel: '',
      TLevel: '',
      FName: '',
      TName: '',
      SelectOption: undefined,
      ServerName: undefined,
      ClassTxt: '',
      FUserID: '',
      TUserID: '',
    }
    this.onCopy = this.onCopy.bind(this);
    this.FindUser = this.FindUser.bind(this);
    this.onSearchFrom = this.onSearchFrom.bind(this);
    this.onSearchTo = this.onSearchTo.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onTxtAreaChange = this.onTxtAreaChange.bind(this);
  }


  async onSearchFrom(value: string, event: any) {
    if (value.length < 1) {
      MessageInfo('Info', 'input From value!');
      return;
    }
    if (this.state.ServerName === undefined || this.state.ServerName.length < 1) {
      MessageInfo('Info', 'please select from name!');
      return;
    }
    const data = await this.FindUser(value, this.state.ServerName);
    if (data) {
      this.setState({FLevel: data.level, FName: data.name})
    }
  }

  async onSearchTo(value: string, event: any) {
    if (value.length < 1) {
      MessageError('Error', 'input To value');
      return;
    }
    const data = await this.FindUser(value, '');
    if (data) {
      this.setState({TLevel: data.level, TName: data.name})
    }
  }

  async FindUser(value: string, name: string) {
    this.setState({isOpen: true});
    const rsp = await PostServer(MethodFindNetUserInfo, JSON.stringify({userid: value, fname: name}));
    this.setState({isOpen: false});
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    return {level: rsp.level, name: rsp.name}
  }

  async onCopy() {
    // const fid = document.getElementById('fid') as HTMLInputElement;
    // const tid = document.getElementById('tid') as HTMLInputElement;
    if (this.state.FUserID.trim().length < 1 || this.state.TUserID.trim().length < 1) {
      MessageInfo('Info', 'From,To is Null');
      return;
    }
    if (this.state.ServerName === undefined || this.state.ServerName.length < 1) {
      MessageInfo('Info', 'please select from name!');
      return;
    }
    this.setState({isOpen: true});
    const data = await PostServer(MethodCouldCopyApps, JSON.stringify({
      fromUserId: this.state.FUserID,
      toUserId: this.state.TUserID,
      fromApp: this.state.ServerName,
      classes: this.state.ClassTxt || '',
    }));
    this.setState({isOpen: false});
    if (data === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (data.err_code !== 0) {
      MessageError('Error', `Server to Code:${data.err_code}`);
      return;
    }
    MessageSuccess('Success', 'Copy Success!');
  }

  async componentDidMount() {
    const data = await PostServer(MethodGetCrossUserSave, JSON.stringify(''));
    if (data === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (data.err_code !== 0) {
      MessageError('Error', `Server to Code:${data.err_code}`);
      return;
    }
    const html = (
      <>
        {data.apps.map((item: string, idx: number) => {
          return <Select.Option value={item} key={idx + 400}>{item}</Select.Option>
        })}
      </>);
    this.setState({SelectOption: html})
  }

  onChange(value: string) {
    this.setState({ServerName: value})
  }

  onTxtAreaChange(e: any) {
    this.setState({ClassTxt: e.currentTarget.value});
  }

  indexPage = ['sub2', '303'];

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <>
          <Row>
            <Col span={5}/>
            <Col span={9}>
              <Space>
                <Typography.Title level={5} style={{margin: 0}}>Select From Server:</Typography.Title>
                <Select
                  onChange={this.onChange}
                  style={{width: '200px'}}
                >
                  {this.state.SelectOption}
                </Select>
              </Space>
            </Col>
            <Col span={10}/>
          </Row>
          <br/>
          <Row>
            <Col span={5}/>
            <Col span={7}>
              <Input.Search
                id='fid'
                placeholder="Input From UserID"
                allowClear
                enterButton="From UserID"
                size="large"
                onSearch={this.onSearchFrom}
                onChange={(e) => {
                  this.setState({FUserID: e.target.value});
                }}
              />
            </Col>
            <Col span={7}>
              <Input.Search
                id='tid'
                placeholder="Input To UserID"
                allowClear
                enterButton="To UserID"
                size="large"
                onSearch={this.onSearchTo}
                onChange={(e) => {
                  this.setState({TUserID: e.target.value});
                }}
              />
            </Col>
            <Col span={5}/>
          </Row>
          <br/>
          <Row>
            <Col span={5}/>
            <Col span={7}>
              <Descriptions title="From User Info" column={1}>
                <Descriptions.Item label="F_UserLevel">{this.state.FLevel}</Descriptions.Item>
                <Descriptions.Item label="F_UserName">{this.state.FName}</Descriptions.Item>
              </Descriptions>
            </Col>

            <Col span={7}>
              <Descriptions title="To User Info" column={1}>
                <Descriptions.Item label="T_UserLevel">{this.state.TLevel}</Descriptions.Item>
                <Descriptions.Item label="T_UserName">{this.state.TName}</Descriptions.Item>
              </Descriptions>

            </Col>
            <Col span={5}/>
          </Row>
          <Row>
            <Col span={5}/>
            <Col span={14}>
              <Typography.Title level={5}>自定义Class(逗号分割):</Typography.Title>
              <TextArea id={'customClass'} onChange={this.onTxtAreaChange}/>
            </Col>
            <Col span={5}/>
          </Row>
          <br/>
          <Row>
            <Col span={17}/>
            <Col span={2}>
              <Button type="primary" block onClick={this.onCopy}>
                Copy User
              </Button>
            </Col>
            <Col span={5}/>
          </Row>
        </>
      </DefaultTemplate>
    );
  }
}