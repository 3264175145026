import {ColumnsType} from "antd/es/table/Table";

export type WorldCupType = {
  id: number,
  win: string,
  main: string,
  guest: string,
}

export const WorldCupColumns:ColumnsType<WorldCupType> =[
  {
    title:'Guess ID',
    dataIndex:'id',
  },
  {
    title:'Win Team',
    dataIndex:'win',
  },
  {
    title:'Main Team',
    dataIndex:'main',
  },
  {
    title:'Guest Team',
    dataIndex:'guest',
  }
]