import React, {Component} from "react"
import DefaultTemplate from "../../Template/DefaultTemplate";
import {Button, Form, Input, Transfer, Select} from "antd";
import '../../css/style_app.css'
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {TransferDirection} from "antd/lib/transfer";
import PostServer from "../../Utils/http";
import {GetMenus, MenuItems} from "../../Template/DefaultRoutes";
import {LoginData} from "../../Local/local";
import {MethodAddUser} from "../../Utils/method";
import {MessageError, MessageInfo, MessageSuccess} from "../../Template/MessageNet";

export default class AddAdmin extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      targetKeys: [],
      selectedKeys: [],
    };
    this.onChange = this.onChange.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }

  async onFinish(values: any) {
    if (values.password !== values.password2) {
      MessageError('Error', '两次密码,输入不一致!');
      return
    }
    if (values.menus === undefined || values.menus.length < 1) {
      MessageInfo('Info', '未选择菜单功能!');
      return
    }
    if (values.level === undefined) {
      MessageInfo('Info', '请选择用户等级!');
      return;
    }
    this.setState({isOpen: true});
    const data = await PostServer(MethodAddUser, JSON.stringify({
      token: LoginData.Token,
      account: values.account,
      level: Number(values.level),
      password: values.password,
      menus: GetMenus(values.menus)
    }));
    this.setState({isOpen: false});
    if (data === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (data.err_code !== 0) {
      MessageError('Error', `Server to Code:${data.err_code}`);
      return;
    }
    MessageSuccess('Success', '添加用户成功!');
  }

  onChange(nextTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) {
    this.setState({targetKeys: nextTargetKeys});
  }

  onSelectChange(sourceSelectedKeys: string[], targetSelectedKeys: string[]) {
    this.setState({selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]});
  }

  indexPage: string[] = ['sub100', '1001'];
  
  layout = {
    labelCol: {span: 3},
    wrapperCol: {span: 21},
  };

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <div className={"login-div-center"}>
          <Form
            layout={'horizontal'}
            onFinish={this.onFinish}
            style={{
              width: '70vh'
            }}
            initialValues={{level: '1'}}
          >
            <Form.Item
              {...this.layout}
              name="account"
              label={'Account:'}
              rules={[
                {
                  required: true,
                  message: 'Please input your UserName!',
                },
                {
                  type: 'string',
                  min: 6,
                }
              ]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="UserName"/>
            </Form.Item>
            <Form.Item
              {...this.layout}
              name="password"
              label={'Password:'}
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
                {
                  type: 'string',
                  min: 6,
                }
              ]}
            >
              <Input.Password placeholder="Password" prefix={<LockOutlined/>}/>
            </Form.Item>
            <Form.Item
              {...this.layout}
              name="password2"
              label={'Password:'}
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
                {
                  type: 'string',
                  min: 6,
                }
              ]}
            >
              <Input.Password placeholder="Password" prefix={<LockOutlined/>}/>
            </Form.Item>

            <Form.Item
              {...this.layout}
              name="level"
              label={'User Level:'}
              style={{textAlign: "left"}}>
              <Select
                style={{
                  width: '50%',
                }}
                allowClear
              >
                <Select.Option value="1">管理者</Select.Option>
                <Select.Option value="2">普通</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name='menus'>
              <Transfer
                titles={['Menus', 'Apply Menus']}
                dataSource={MenuItems.menus}
                onChange={this.onChange}
                targetKeys={this.state.targetKeys}
                render={(item => item.title)}
                listStyle={{width: '100%', height: '50%'}}
                locale={{itemUnit: 'Select', itemsUnit: 'Select'}}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Add User
              </Button>
            </Form.Item>
          </Form>
        </div>
      </DefaultTemplate>
    );
  }
}