import React, {Component, ReactNode} from "react";
import {EditableProTable, ProColumns} from "@ant-design/pro-components";
import {DatePicker, Divider, Typography, InputNumber, Select, Button, Popconfirm} from 'antd';
import DefaultTemplate from "../../Template/DefaultTemplate";
import {SpringFestivalRankType, SpringFestivalSource} from "./SpringFestivalModel";
import PostServer, {PostXlsx} from "../../Utils/http";
import {
  MethodSpringFestivalRank,
  MethodSpringFestivalDel, MethodSpringFestivalExport,
} from "../../Utils/method";
import moment from "moment";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import {Option} from "antd/es/mentions";
import {MessageError} from "../../Template/MessageNet";


export default class SpringFestivalRank extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      visible: false,
      UserId: '',
      Call: this.onCall,
      RankIdx: 1,
      RankPool: 1,
      RankNum: 100,
      RankTeam: 1,
      RankDate: moment(new Date()).format('YYYY-MM-DD'),
    };
    this.onDel = this.onDel.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.GetSource = this.GetSource.bind(this);
    this.onCall = this.onCall.bind(this);
    this.onExport = this.onExport.bind(this);
    this.onChangeTeam = this.onChangeTeam.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeIdx = this.onChangeIdx.bind(this);
  }

  RankColumns: ProColumns<SpringFestivalRankType>[] = [
    {
      title: 'Rank IDX',
      dataIndex: 'order',
      readonly: true,
      width: '120px'
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      readonly: true,
      width: '120px'
    }, {
      title: 'User Name',
      dataIndex: 'userName',
      readonly: true,
      width: '120px'
    }, {
      title: 'User Score',
      dataIndex: 'userScore',
      width: '80px'
    }, {
      title: 'User Level',
      dataIndex: 'userLevel',
      width: '80px',

    }, {
      title: 'Operation',
      valueType: 'option',
      width: 120,
      render: (text, record, idx, action) => [
        <Popconfirm
          title="删除,将删除所有榜的信息,并加入黑名单?"
          onConfirm={() => this.onDel(text, record, idx, action)}
          okText="Yes"
          cancelText="No"
        >
          <a href="#">DELETE</a>
        </Popconfirm>
      ]
    }
  ];


  onCall() {
    this.setState({visible: false, UserId: ''});
  }

  async onDel(text: ReactNode, record: SpringFestivalRankType, index: number, _: any) {
    const req = {
      date: this.state.RankDate,
      team: this.state.RankTeam,
      pool: this.state.RankPool,
      userId: record.userId
    };
    console.log(record);
    const rsp = await PostServer(MethodSpringFestivalDel, JSON.stringify(req));
    this.setState({isOpen: false});
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    SpringFestivalSource.splice(index, 1);
    this.formRef?.current.reload();
  }

  async onSelect() {
    this.setState({isOpen: true});
    const req = {
      date: this.state.RankDate,
      team: this.state.RankTeam,
      pool: this.state.RankPool,
      rankIdx: this.state.RankIdx,
      count: this.state.RankNum,
    };
    const rsp = await PostServer(MethodSpringFestivalRank, JSON.stringify(req))
    this.setState({isOpen: false})
    if (rsp === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (rsp.err_code !== 0) {
      MessageError('Error', `Server to Code:${rsp.err_code}`);
      return;
    }
    console.log(rsp.ranks);
    SpringFestivalSource.splice(0, SpringFestivalSource.length);
    if (rsp.ranks) {
      SpringFestivalSource.push(...rsp.ranks);
    }
    this.formRef?.current.reload();
  }

  async onExport() {
    this.setState({isOpen: true});
    const req = {
      date: this.state.RankDate,
      team: this.state.RankTeam,
      pool: this.state.RankPool,
      rankIdx: this.state.RankIdx,
      count: this.state.RankNum,
    };
    const fileName = `springFestival_${this.state.RankDate}.xlsx`;
    await PostXlsx(MethodSpringFestivalExport, JSON.stringify(req), fileName);
    this.setState({isOpen: false});
  }

  GetSource() {
    let tableSource: SpringFestivalRankType[] = JSON.parse(JSON.stringify(SpringFestivalSource));
    return tableSource;
  }

  componentWillUnmount() {
    SpringFestivalSource.splice(0, SpringFestivalSource.length);
  }

  onChangeTeam(t: any) {
    this.setState({RankTeam: Number(t)});
  }

  onChangeType(t: any) {
    this.setState({RankIdx: Number(t)});
  }

  onChangeIdx(t: any) {
    this.setState({RankPool: Number(t)});
  }


  formRef: any = {};
  indexPage: string[] = ['sub150', '1501'];
  RankList = [
    {Id: 1, Desc: 'HURT'},
    {Id: 2, Desc: 'KILL'},
    {Id: 3, Desc: 'LIFE'},
  ];

  Teams = [
    {Id: 1, Desc: 'Light'},
    {Id: 2, Desc: 'Dark'},
  ];

  RankIDX = [
    {Id: 1, Desc: '1'}, {Id: 2, Desc: '2'}, {Id: 3, Desc: '3'}, {Id: 4, Desc: '4'}, {Id: 5, Desc: '5'},
    {Id: 6, Desc: '6'}, {Id: 7, Desc: '7'}, {Id: 8, Desc: '8'}, {Id: 9, Desc: '9'}, {Id: 10, Desc: '10'},
    {Id: 11, Desc: '11'}, {Id: 12, Desc: '12'}, {Id: 13, Desc: '13'}, {Id: 14, Desc: '14'}, {Id: 15, Desc: '15'},
    {Id: 16, Desc: '16'}, {Id: 17, Desc: '17'}, {Id: 18, Desc: '18'}, {Id: 19, Desc: '19'}, {Id: 20, Desc: '20'},
  ];

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <EditableProTable<SpringFestivalRankType>
          rowKey={'order'}
          scroll={{x: 960}}
          columns={this.RankColumns}
          recordCreatorProps={false}
          actionRef={this.formRef}
          toolBarRender={() => [
            <Typography.Text style={{fontSize: 15}}>Team:</Typography.Text>,
            <Select defaultValue={this.Teams[0].Desc} style={{width: 100}} onChange={this.onChangeTeam}>
              {this.Teams.map((item, idx) => {
                return (<Option value={`${item.Id}`}>{item.Desc}</Option>)
              })}
            </Select>,
            <Typography.Text style={{fontSize: 15}}>Type:</Typography.Text>,
            <Select defaultValue={this.RankList[0].Desc} style={{width: 100}} onChange={this.onChangeType}>
              {this.RankList.map((item, idx) => {
                return (<Option value={`${item.Id}`}>{item.Desc}</Option>)
              })}
            </Select>,
            <Typography.Text style={{fontSize: 15}}>IDX:</Typography.Text>,
            <Select defaultValue={this.RankIDX[0].Desc} style={{width: 80}} onChange={this.onChangeIdx}>
              {this.RankIDX.map((item, idx) => {
                return (<Option value={`${item.Id}`}>{item.Desc}</Option>)
              })}
            </Select>,
            <Divider type="vertical"/>,
            <InputNumber addonBefore="Count" style={{width: 150}} defaultValue={this.state.RankNum}
                         value={this.state.RankNum}
                         onChange={(v) => {
                           this.setState({RankNum: v});
                         }}/>,
            <Divider type="vertical"/>,
            <Typography.Text style={{fontSize: 15}}>Select DATE:</Typography.Text>,
            <DatePicker defaultValue={moment(new Date())}
                        showTime={{defaultValue: moment('2000-05-05 05', 'YYYY-MM-DD')}}
                        format={'YYYY-MM-DD'} locale={locale} size={'large'}
                        onChange={(_, dateString) => {
                          this.setState({RankDate: dateString});
                        }}
            />,
            <Button type="primary" ghost onClick={this.onSelect}>
              SELECT
            </Button>,
            <Button type="primary" ghost onClick={this.onExport}>
              EXPORT
            </Button>
          ]}
          pagination={{
            pageSize: 20,
          }}
          request={async () => {
            const dbSource = this.GetSource();
            return {
              data: dbSource,
              total: dbSource.length,
              success: true,
            }
          }}
        >
        </EditableProTable>

      </DefaultTemplate>
    );
  }
}