import React, {Component} from "react";
import DefaultTemplate from "../../Template/DefaultTemplate";
import {Button, Form, Input} from "antd";
import {ProFormDateTimePicker} from "@ant-design/pro-components";
import {SendOutlined, RollbackOutlined} from "@ant-design/icons"


export default class MarkSeverTime extends Component<any, any> {

  constructor(props:any) {
    super(props);
    this.onFinish = this.onFinish.bind(this);
    this.onReset = this.onReset.bind(this);
    this.state ={
      ipPort:"http://172.31.97.20:3001/",
    }
  }

  onFinish(values: string) {
    console.log(values)
  }

  onReset() {
    console.log(this.state.ipPort)
    this.setState({ipPort:'2222'});
    console.log(1)
  }

  onChange(e:any){
    console.log( e.current.value)
  }



  indexPage: string[] = ['sub100', '1002']

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage}>
        <Input addonBefore="IP-PORT" defaultValue={'http://172.31.97.20:3001/'} style={{width: '40vh'}} onChange={this.onChange} />
        <div className={"div-center"}>
          <Form
            layout="horizontal"
            onFinish={this.onFinish}
            style={{
              width: '400px'
            }}
          >
            <Form.Item
              name="nowtime"
              label={'Now Time:'}
            >
              <Input placeholder="Borderless" bordered={false} value='34234'></Input>
            </Form.Item>
            <Form.Item
              name="updatetime"
              label={'Update Time:'}
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!',
                }
              ]}
            >
              <ProFormDateTimePicker width="md" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button" icon={<SendOutlined/>}>
                Update Time
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" className="login-form-button" icon={<RollbackOutlined/>} onClick={this.onReset}>
                Reset Time
              </Button>
            </Form.Item>
          </Form>
        </div>
      </DefaultTemplate>
    );
  };
}