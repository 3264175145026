import React, {Component} from "react";
import DefaultTemplate from "../../Template/DefaultTemplate";
import {Button, Form, Input} from "antd";
import {LockOutlined} from "@ant-design/icons";
import PostServer from "../../Utils/http";
import {MethodMarkPassword} from "../../Utils/method";
import {MessageError, MessageSuccess} from "../../Template/MessageNet";

export default class MarkPassword extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {isOpen: false}
    this.onFinish = this.onFinish.bind(this);
  }

  indexPage: string[] = ['sub100', '1003'];

  async onFinish(values: any) {
    if (values.newpwd !== values.newpwdto) {
      MessageError('Error', '新密码2次输入不相同!');
      return;
    }
    this.setState({isOpen: true})
    const req = await PostServer(MethodMarkPassword, JSON.stringify({
      used_pwd: values.usedpwd,
      new_pwd: values.newpwd
    }));
    this.setState({isOpen: false})
    if (req === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (req.err_code !== 0) {
      MessageError('Error', `Server to Code:${req.err_code}`);
      return;
    }
    MessageSuccess('Success', 'Mark Success!');
  }

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <div className={"login-div-center"}>
          <Form
            layout="horizontal"
            onFinish={this.onFinish}
            style={{
              width: '600px'
            }}
          >
            <Form.Item
              name="usedpwd"
              label={'旧密码:'}
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
                {
                  type: 'string',
                  min: 6,
                }
              ]}
            >
              <Input.Password prefix={<LockOutlined/>} placeholder="input used password!"/>
            </Form.Item>
            <Form.Item
              name="newpwd"
              label={'新密码:'}
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
                {
                  type: 'string',
                  min: 6,
                }
              ]}
            >
              <Input.Password prefix={<LockOutlined/>} placeholder="input new password"/>
            </Form.Item>
            <Form.Item
              name="newpwdto"
              label={'新密码:'}
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
                {
                  type: 'string',
                  min: 6,
                }
              ]}
            >
              <Input.Password prefix={<LockOutlined/>} placeholder="input new password"/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Mark Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </DefaultTemplate>
    );
  }
}
