import React, {Component, ReactNode} from 'react';
import {ProCoreActionType, ProTable} from "@ant-design/pro-components";
import {Button, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import {TableListItem, TableColumns, JsonKey, TableDataSource} from './Model';
import AddMailForm from './AddMailForm';
import DefaultTemplate from "../../Template/DefaultTemplate";
import {LoadFile} from "../../Utils/util";
import PostServer from "../../Utils/http";
import {MethodDelRow, MethodMarkSysMail, MethodParseCouldSysMail, MethodSysMail} from "../../Utils/method";
import {MessageError, MessageInfo, MessageSuccess} from "../../Template/MessageNet";

export default class MailTable extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      json: {},
      call: this.Call,
      isOpen: false,
    }
    this.CreateMail = this.CreateMail.bind(this);
    this.ImportFile = this.ImportFile.bind(this);
    this.Call = this.Call.bind(this);
    this.DelRow = this.DelRow.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onEditDel = this.onEditDel.bind(this);
    this.onEditSave = this.onEditSave.bind(this);
  }

  Call = (value: any) => {
    this.setState({visible: false})
    this.useTableRef.current.reload();
  }

  GetSource() {
    TableDataSource.sort((a: any, b: any) => {
      return b.key - a.key;
    })
    let tableSource: TableListItem[] = JSON.parse(JSON.stringify(TableDataSource));
    return tableSource;
  }

  CreateMail() {
    this.setState({visible: true, json: JsonKey, call: this.Call})
  }

  LoadColumns() {
    let columns = JSON.parse(JSON.stringify(TableColumns));
    columns.push({
      title: "操作",
      width: 250,
      key: "option",
      valueType: "option",
      align: 'center',
      fixed: "right",
      render: (_: ReactNode, row: TableListItem, index: number, action: any) =>
        [
          <a key={'editable'} onClick={() => this.onEdit(row, index, action)} style={
            row.status === 'online' ? {color: 'rgba(0,0,0,.25)'} : {}
          }>编辑</a>,
          <a key="link_del" onClick={() => this.DelRow(row, index, action)}>删除</a>,
          <a key="link_cpy" onClick={() => this.CopyRow(row, index, action)}>复制</a>,
          <a key="link_send" onClick={() => this.SendRow(row, index, action)}>发布</a>],
    });
    return columns;
  }

  onEdit(row: TableListItem, index: number, action: ProCoreActionType<any>) {
    if (row.status === 'online') {
      MessageInfo('Info', '已发布,不允许编辑!');
      return;
    }
    action?.startEditable?.(row.key);
  }

  async DelRow(row: TableListItem, index: number, action: ProCoreActionType<any>) {
    this.setState({isOpen: true, visible: false});
    const req = await PostServer(MethodDelRow, JSON.stringify({type: 1, key: row.key}));
    this.setState({isOpen: false});
    if (req === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (req.err_code !== 0) {
      MessageError('Error', 'Server to Code:${req.err_code}');
      return;
    }
    TableDataSource.splice(index, 1);
    action?.reload();
  };

  async SendRow(row: TableListItem, index: number, action: any) {
    if (row.status === 'online') {
      MessageInfo('Info', '此数据已发布,需要重新发布,请复制/创建!');
      return;
    }
    this.setState({isOpen: true});
    const data = await PostServer(MethodParseCouldSysMail, JSON.stringify({
      key: row.key,
    }));
    this.setState({isOpen: false});
    if (data === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (data.err_code !== 0) {
      MessageError('Error', `Server to Code:${data.err_code}`);
      return;
    }
    TableDataSource.forEach((item) => {
      if (item.key === row.key) {
        item.status = 'online';
      }
    });
    action.reload(index);
    MessageSuccess('Success', '发布成功!');
  };

  CopyRow(row: TableListItem, index: number, action: ProCoreActionType<any>) {
    const data = JSON.parse(JSON.stringify(row));
    this.setState({visible: true, json: data, call: this.Call})
  };

  ImportFile(file: any) {
    LoadFile(file, (data: any) => {
      this.setState({visible: true, json: data, call: this.Call})
    });
  }

  async onEditSave(rowKey: any, newRow: any, oldRow: any) {
    this.setState({isOpen: true});
    newRow.id = Number(newRow.id);
    const req = await PostServer(MethodMarkSysMail, JSON.stringify({sys_mail: newRow}));
    this.setState({isOpen: false, visible: false})
    if (req === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (req.err_code !== 0) {
      MessageError('Error', `Server to Code:${req.err_code}`);
      return;
    }
    MessageSuccess('Success', '保存成功!');
  }

  async onEditDel(key: any, rowData: any) {
    this.setState({isOpen: true});
    const req = await PostServer(MethodDelRow, JSON.stringify({type: 1, key: rowData.key}));
    this.setState({isOpen: false, visible: false})
    if (req === undefined) {
      MessageError('Error', '请求失败!');
      return;
    }
    if (req.err_code !== 0) {
      MessageError('Error', `Server to Code:${req.err_code}`);
      return;
    }
    TableDataSource.splice(rowData.index, 1);
    console.log(TableDataSource);
    this.useTableRef?.current?.reload();
  }

  async componentDidMount() {
    const data = await PostServer(MethodSysMail, '');
    if (data === undefined) {
      console.log('请求失败!')
      return;
    }
    if (data.err_code !== 0) {
      console.log(`Server to Code:${data.err_code}`)
      return;
    }
    TableDataSource.splice(0, TableDataSource.length);
    TableDataSource.push(...data.mail);
    this.useTableRef.current.reload();
  }

  useTableRef: any = {};
  indexPage: string[] = ['sub1', '201']

  render() {
    return (
      <DefaultTemplate subKeys={[this.indexPage[0]]} page={this.indexPage} isOpen={this.state.isOpen}>
        <ProTable<TableListItem>
          actionRef={this.useTableRef}
          rowKey={'key'}
          columns={this.LoadColumns()}
          search={false}
          options={false}
          headerTitle="Mail"
          scroll={{
            x: 1300
          }}
          pagination={{
            pageSize: 20,
          }}
          request={async () => {
            const dataSource = this.GetSource()
            return {
              data: dataSource,
              success: true,
              total: dataSource.length,
            };
          }}
          toolBarRender={() => [
            <>
              <Upload action="" accept="json/plain" beforeUpload={this.ImportFile}
                      showUploadList={false}>
                <Button icon={<UploadOutlined/>}>加载JSON</Button>
              </Upload>
              <Button type="primary" key="primary" onClick={this.CreateMail}>
                创建邮件
              </Button>
            </>
          ]}
          editable={{
            type: 'multiple',
            onSave: this.onEditSave,
            onDelete: this.onEditDel,
          }}
        />
        <AddMailForm visible={this.state.visible} json={this.state.json} call={this.state.call}/>
      </DefaultTemplate>
    );
  }
}